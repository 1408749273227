import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { useAuth } from "../../../../auth/core/AuthContext";
import { Button } from "primereact/button";

export default function SupplierDatatable({
  supplier,
  showAddDialog,
  setDisplayDeleteDialog,
  setDisplayUpdateDialog,
  setCompany,
  setAdress,
  setPhone,
  setActivityArea,
  setResponsiblePerson,
  setSelectedSupplier,
}) {
  const { userData } = useAuth();

  const getTurkishLabel = (url) => {
    switch (url) {
      case "/supplier-add":
        return "Tedarikçi";
      default:
        return "Sayfa";
    }
  };
  const url = "/supplier-add";
  const turkishLabel = getTurkishLabel(url);

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };

  const EditBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ color: "#f39f5a" }}>
            <Button
              severity="warning"
              onClick={() => {
                setDisplayUpdateDialog((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));
                setCompany(rowData.company);
                setAdress(rowData.address);
                const formattedActivityArea = rowData.activity_area.split(", ");
                setActivityArea(formattedActivityArea);
                setPhone(rowData.phone);
                setResponsiblePerson(rowData.responsible_person);
                setSelectedSupplier(rowData.id);
              }}
              icon="pi pi-pencil"
            />
          </div>
          <div style={{ color: "#ae445a" }}>
            <Button
              icon="pi pi-trash"
              onClick={() => {
                setDisplayDeleteDialog((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));
                setCompany(rowData.company);
                setAdress(rowData.address);
                const formattedActivityArea = rowData.activity_area.split(", ");
                setActivityArea(formattedActivityArea);
                setPhone(rowData.phone);
                setResponsiblePerson(rowData.responsible_person);
                setSelectedSupplier(rowData.id);
              }}
              severity="danger"
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  const columns = [
    { field: "actions", header: "İşlemler", body: EditBodyTemplate },
    { field: "company", header: "Tedarikçi" },
    { field: "responsible_person", header: "Sorumlu Kişi" },
    { field: "address", header: "Adres" },
    { field: "phone", header: "Telefon Numarası" },
    { field: "activity_area", header: "Sorumluluk Alanı" },
  ];

  return (
    <Card>
      <BreadCrumb model={itemsBread} home={home} style={{ padding: "1rem" }} />
      <CardHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">Tedarikçi Ekle</Heading>
          {userData?.type === "1" && (
            <div>
              <Button
                label="Yeni"
                onClick={showAddDialog}
                className="btn-excel-two"
              />
            </div>
          )}
        </Flex>
      </CardHeader>
      <DataTable
        stripedRows
        size="small"
        value={supplier}
        tableStyle={{ minWidth: "50rem" }}
        rows={15}
        rowsPerPageOptions={[15, 30, 60, 100]}
        scrollHeight="65vh"
        scrollable
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate=" {totalRecords} tedarikçiden {first} - {last} arası gösteriliyor"
      >
        {columns.map((col, i) => (
          <Column
            key={col?.field}
            field={col?.field}
            header={col?.header}
            body={col?.body}
          />
        ))}
      </DataTable>
    </Card>
  );
}
