import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import moneyType from "../../../../../_ekdizayn/api/currency-type.json";

import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
export default function ServicesDatatable({
  services,
  itemsBread,
  home,
  setAddServices,
  setUpdateServices,
  setDeleteServices,
  setForm,
  setSelectedId,
}) {
  const EditBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ color: "#f39f5a" }}>
            <Button
              severity="warning"
              onClick={() => {
                setUpdateServices((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));
                setForm({
                  servicesType: rowData?.service_type,
                  stock: rowData?.stock,
                  servicesName: rowData?.service_name,
                  vatRate: rowData?.vat_rate,
                  currency: rowData?.currency,
                  buying: rowData?.buying,
                  sales: rowData?.sales,
                  description: rowData?.description,
                });
                setSelectedId(rowData?.id);
              }}
              icon="pi pi-pencil"
            />
          </div>
          <div style={{ color: "#ae445a" }}>
            <Button
              icon="pi pi-trash"
              onClick={() => {
                setDeleteServices((prevDialogs) => ({
                  ...prevDialogs,
                  [rowData.id]: true,
                }));
                setForm({
                  servicesType: rowData?.service_type,
                  servicesName: rowData?.service_name,
                  stock: rowData?.stock,
                  vatRate: rowData?.vat_rate,
                  currency: rowData?.currency,
                  buying: rowData?.buying,
                  sales: rowData?.sales,
                  description: rowData?.description,
                });
                setSelectedId(rowData?.id);
              }}
              severity="danger"
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  const moneyTypeSearch = (rowData) => {
    const money = moneyType.find(
      (money) => money.value.toString() === rowData.currency
    );
    if (money) {
      return money.label;
    } else {
      return "Bilinmeyen";
    }
  };

  const columns = [
    { field: "code", header: "İşlemler", body: EditBodyTemplate },
    { field: "service_code", header: "Hizmet Kodu" },
    { field: "service_name", header: "Hizmet Adı" },
    { field: "service_type", header: "Hizmet Türü" },
    { field: "stock", header: "Stok" },
    { field: "vat_rate", header: "KDV Oranı(%)" },
    { field: "currency", header: "Para Birimi", body: moneyTypeSearch },
    { field: "buying", header: "Alış" },
    { field: "sales", header: "Satış" },
    { field: "description", header: "Açıklama" },
  ];

  return (
    <div className="mt-3">
      <BreadCrumb model={itemsBread} home={home} style={{ padding: "1rem" }} />
      <Card>
        <CardHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="md">Hizmetler</Heading>

            <div>
              <Button
                label="Yeni"
                onClick={() => setAddServices(true)}
                className="btn-excel-two"
              />
            </div>
          </Flex>
        </CardHeader>

        <DataTable
          tableStyle={{ minWidth: "50rem" }}
          stripedRows
          value={services}
          size="small"
          rows={15}
          rowsPerPageOptions={[15, 30, 60, 100]}
          scrollHeight="65vh"
          scrollable
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate=" {totalRecords} hizmetten {first} - {last} arası gösteriliyor"
        >
          {columns.map((col, i) => (
            <Column
              key={col?.field}
              field={col?.field}
              header={col?.header}
              body={col?.body}
            />
          ))}
        </DataTable>
      </Card>
    </div>
  );
}
