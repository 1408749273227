import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  AddSupportService,
  CompletedSupportService,
  DeleteTaskService,
  EditTaskService,
  TransferSupportService,
} from "../../../../core/Services";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../auth/core/AuthContext";

export default function Waiting() {
  const [addModal, setAddModal] = useState(false);
  const [visibleDialogs, setVisibleDialogs] = useState(false);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [dep, setDep] = useState("");
  const [cus, setCus] = useState("");
  const [desc, setDesc] = useState("");
  const [taskStatus, setTaskStatus] = useState("");
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedCustomerUniqueId, setSelectedCustomerUniqueId] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [dialogs, setDialogs] = useState({});
  const toast = useRef(null);
  const [inputErrors, setInputErrors] = useState({
    cus: false,
    dep: false,
    desc: false,
    taskStatus: false,
  });
  const isButtonDisable = false;
  const taskData = useSelector((state) => state.TaskReduce.value);
  const { userData } = useAuth();
  const customerData = useSelector((state) => state.CustomerReduce.value);

  const hideDialog = () => {
    setAddModal(false);
    setSelectedCustomerUniqueId("");
    setCus("");
    setDesc("");
    setTaskStatus("");
    setDep("");
    setInputErrors({
      cus: false,
      dep: false,
      desc: false,
      taskStatus: false,
    });
  };

  const subjects = [
    { label: "Destek", value: "Destek" },
    { label: "Satış", value: "Satış" },
    { label: "Muhasebe", value: "Muhasebe" },
    { label: "Şikayet", value: "Şikayet" },
    { label: "Diğer", value: "Diğer" },
  ];

  const workingBodyTemplate = (rowData) => {
    const getStatusLabel = (working_condition) => {
      switch (working_condition) {
        case "0":
          return "Yüksek";

        case "1":
          return "Normal";

        case "2":
          return "Kritik";

        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.working_condition);

    return <Tag value={statusLabel} severity={getSeverity(rowData)}></Tag>;
  };
  const getSeverity = (jobs) => {
    switch (jobs.working_condition) {
      case "0":
        return "warning";

      case "1":
        return "success";

      case "2":
        return "danger";

      default:
        return null;
    }
  };

  const handleCompleteTask = async () => {
    try {
      if (!selectedTask) {
        console.error("Tamamlanacak destek bulunamadı.");
        return;
      }

      await CompletedSupportService(selectedTask.id, userData.name);

      setDialogs(false);
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };
  const handleTransferTask = async () => {
    try {
      if (!selectedTask) {
        console.error("Tamamlanacak destek bulunamadı.");
        return;
      }

      await TransferSupportService(selectedTask.id);

      setDialogs(false); // Dialog'u kapat
    } catch (error) {
      console.error("Hata:", error.message);
    }
  };
  const getCustomerName = (rowData) => {
    const customer = customers?.find(
      (cust) => cust.id.toString() === rowData?.name
    );
    return customer ? customer.title : "Bilinmeyen";
  };

  const newCreate = async () => {
    if (!cus || !dep || !desc || !taskStatus) {
      setInputErrors({
        cus: !cus,
        dep: !dep,
        desc: !desc,
        taskStatus: !taskStatus,
      });
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen boş kısımları doldurun.",
      });
      return;
    }

    await AddSupportService(
      cus,
      selectedCustomerUniqueId,
      desc,
      dep,
      taskStatus
    );
    setAddModal(false);
    setSelectedCustomerUniqueId("");
    setCus("");
    setDesc("");
    setDep("");
    setTaskStatus("");
  };

  const statusType = [
    { label: "Normal", value: "1" },
    { label: "Yüksek", value: "0" },
    { label: "Kritik", value: "2" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTasks(taskData);

        setCustomers(customerData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [addModal, taskData, customerData]);

  const handleEditSupport = async () => {
    await EditTaskService(selectedId, cus, desc, dep, taskStatus);

    setDialogs(false);
    setSelectedId(null);
    setCus(null);
    setDesc(null);
    setDep(null);
    setTaskStatus(null);
  };

  const EditBodyTemplate = (tasks) => {
    const isVisible = dialogs[tasks.id] || false;
    useEffect(() => {
      if (isVisible) {
        setCus(
          customers?.find((dept) => dept.id.toString() === tasks.name)?.id || ""
        );
        setDep(tasks.department || "");
        setDesc(tasks.desc || "");
        setTaskStatus(tasks.working_condition || "");
      }
    }, [isVisible, tasks]);
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ color: "#f39f5a" }}>
            <Button
              severity="warning"
              onClick={() => {
                setSelectedTask(tasks);
                setDialogs((prevDialogs) => ({
                  ...prevDialogs,
                  [tasks.id]: true,
                }));
                setSelectedId(tasks.id);
              }}
              icon="pi pi-pencil"
            />
          </div>
          <div style={{ color: "#ae445a" }}>
            <Button
              icon="pi pi-trash"
              onClick={() => {
                setSelectedTask(tasks);
                setDisplayDeleteDialog((prevDialogs) => ({
                  ...prevDialogs,
                  [tasks.id]: true,
                }));
                setSelectedId(tasks.id);
                setDisplayDeleteDialog(true);
              }}
              severity="danger"
            />
          </div>
          <div style={{ color: "#00d9ff" }}>
            <Button
              icon="pi pi-check"
              onClick={() => {
                setSelectedTask(tasks);
                setVisibleDialogs((prevDialogs) => ({
                  ...prevDialogs,
                  [tasks.id]: true,
                }));
              }}
              severity="info"
            />
          </div>
        </div>
        <Dialog
          header="İçerik Düzenleme"
          visible={isVisible}
          style={{ width: "50vw" }}
          onHide={() => {
            setDialogs((prevDialogs) => ({
              ...prevDialogs,
              [tasks.id]: false,
            }));
            setSelectedTask(null);
            hideDialog();
          }}
          footer={
            <div>
              <Button
                label="Kaydet"
                icon="pi pi-check"
                onClick={handleEditSupport}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <Dropdown
                  filter
                  options={customers?.map((dep) => ({
                    label: dep.title,
                    value: dep.id,
                  }))}
                  value={cus}
                  className="w-full mb-2"
                  placeholder="Müşteri Adı "
                  onChange={(e) => setCus(e.target.value)}
                  style={{
                    border: "1px solid gray",
                  }}
                />

                <Dropdown
                  filter
                  className="w-full mb-2"
                  options={subjects}
                  value={dep}
                  placeholder="Konu"
                  onChange={(e) => setDep(e.target.value)}
                  style={{
                    border: "1px solid gray",
                  }}
                />

                <InputText
                  type="text"
                  className="p-inputtext-lg w-full mb-2 "
                  placeholder="Açıklama"
                  defaultValue={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />

                <Dropdown
                  filter
                  options={statusType}
                  className="w-full mb-2"
                  placeholder="Durum"
                  value={taskStatus}
                  onChange={(e) => setTaskStatus(e.value)}
                  style={{
                    border: "1px solid gray",
                    marginBottom: "1rem",
                  }}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  };

  const hideDeleteDialog = () => {
    setDialogs((prevDialogs) => ({
      ...prevDialogs,
      [tasks.id]: false,
    }));
    setSelectedId(null);
    setDisplayDeleteDialog(false);
  };
  const handleDeletedSupport = async () => {
    await DeleteTaskService(selectedId);
    setDisplayDeleteDialog(false);
    setSelectedId(null);
  };
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Bekleyen Destek</Heading>
              <div>
                <Button
                  label="Yeni"
                  onClick={() => setAddModal(true)}
                  className="btn-excel-two"
                />
              </div>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />

          <DataTable
            stripedRows
            size="small"
            value={tasks && tasks.filter((tasks) => tasks.tasks_id === "3")}
            dataKey="id"
            tableStyle={{ maxWidth: "85vw" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollHeight="65vh"
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} destekten {first} - {last} arası gösteriliyor"
          >
            {userData?.type === "1" && (
              <Column body={EditBodyTemplate} header="İşlemler"></Column>
            )}

            <Column
              header="Müşteri"
              field="name"
              body={(rowData) => {
                const cust = customers?.find(
                  (dept) => dept.id.toString() === rowData.name
                );
                if (cust) {
                  // Müşteri başlığını 30 karaktere kısalt
                  const shortenedTitle =
                    cust.title && cust.title.length > 25
                      ? cust.title.substring(0, 25) + "..."
                      : cust.title;
                  return shortenedTitle;
                } else {
                  return "Bilinmeyen";
                }
              }}
            ></Column>
            <Column
              style={{ maxWidth: "15rem" }}
              field="department"
              header="Konu"
            ></Column>
            <Column
              style={{ maxWidth: "20rem" }}
              field="desc"
              header="Açıklama"
            ></Column>
            <Column
              style={{ maxWidth: "10rem" }}
              field="priority"
              body={workingBodyTemplate}
              header="Öncelik"
            ></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "50%" }}
        header="Destek Ekle"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content">
          <div className="p-grid p-fluid">
            <div className="col-sm-12">
              <div style={{ marginBottom: "1rem" }}>
                <div style={{ display: "flex" }}>
                  <Dropdown
                    filter
                    onChange={(e) => {
                      setCus(e.target.value);
                      const selectedCustomer = customers.find(
                        (customer) => customer.id === e.target.value
                      );
                      const uniqueId = selectedCustomer
                        ? selectedCustomer.unique_id
                        : null;
                      setSelectedCustomerUniqueId(uniqueId);
                      setInputErrors({ ...inputErrors, cus: false });
                    }}
                    options={
                      customers && customers.length > 0
                        ? customers.map((dep) => ({
                            label: dep.title,
                            value: dep.id,
                            unique_id: dep?.unique_id,
                          }))
                        : []
                    }
                    value={cus}
                    placeholder="Müşteri Adı / Ünvanı"
                    style={{
                      border: inputErrors.cus
                        ? "1px solid red"
                        : "1px solid gray",
                      width: "100%",
                    }}
                  />
                  <div style={{ maxWidth: "0.4rem", marginLeft: "0.5rem" }} />
                  <Button className="btn-plus " style={{ width: "3rem" }}>
                    <Link to="/customer-list">
                      <i
                        className="pi  pi-plus"
                        style={{ fontSize: "1rem" }}
                      ></i>
                    </Link>
                  </Button>
                </div>
                {inputErrors.cus && (
                  <small style={{ color: "red" }}>
                    Lütfen bir müşteri seçin.
                  </small>
                )}
              </div>
            </div>
            <div className="p-col-6" style={{ marginBottom: "1rem" }}>
              <Dropdown
                filter
                options={subjects}
                value={dep}
                placeholder="Konu"
                onChange={(e) => {
                  setDep(e.target.value);
                  setInputErrors({ ...inputErrors, dep: false });
                }}
                style={{
                  border: inputErrors.dep ? "1px solid red" : "1px solid gray",
                  width: "100%",
                }}
              />
              {inputErrors.dep && (
                <small style={{ color: "red" }}>Lütfen bir konu seçin.</small>
              )}
            </div>
            <div className="p-col-6" style={{ marginBottom: "1rem" }}>
              <Dropdown
                filter
                options={statusType}
                placeholder="Durum"
                value={taskStatus}
                onChange={(e) => {
                  setTaskStatus(e.value);
                  setInputErrors({ ...inputErrors, taskStatus: false });
                }}
                style={{
                  border: inputErrors.taskStatus
                    ? "1px solid red"
                    : "1px solid gray",
                  width: "100%",
                }}
              />
              {inputErrors.taskStatus && (
                <small style={{ color: "red" }}>Lütfen bir durum seçin.</small>
              )}
            </div>
            <div className="p-col-6" style={{ marginBottom: "1rem" }}>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Açıklama"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setDesc(inputValue);
                  setInputErrors({
                    ...inputErrors,
                    desc: inputValue.trim() === "",
                  });
                }}
                value={desc}
                style={{
                  border: inputErrors.desc ? "1px solid red" : "1px solid gray",
                  width: "100%",
                }}
              />
              {inputErrors.desc && (
                <small style={{ color: "red" }}>
                  Lütfen bir açıklama girin.
                </small>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button
            label="Ekle"
            disabled={isButtonDisable}
            onClick={newCreate}
            style={{ width: "15%" }}
            className="btn-excel-two"
          />
        </div>
      </Dialog>
      <Dialog
        header="Destek İçeriği"
        visible={visibleDialogs}
        style={{ width: "50vw" }}
        onHide={() => {
          setDialogs((prevDialogs) => ({
            ...prevDialogs,
            [tasks.id]: false, // Görevin visible state'ini false yap
          }));
          setSelectedId(null);
          setVisibleDialogs(false);
        }}
        footer={
          <div>
            <Button
              label="Görev Bölümüne Aktar"
              icon="pi pi-plus"
              onClick={handleTransferTask}
              className="btn-excel-two"
            />
            <Button
              label="Desteği Tamamla"
              icon="pi pi-check"
              onClick={handleCompleteTask}
              className="btn-excel-two"
              style={{ marginLeft: "15px" }}
            />
          </div>
        }
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Müşteri Adı: {getCustomerName(selectedTask)}
          </label>
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">
            Konu : {selectedTask?.department}
          </label>
        </div>
        <div className="field">
          <label className="mb-3 font-bold">
            Açıklama: {selectedTask?.desc}
          </label>
        </div>
      </Dialog>
      <Dialog
        visible={displayDeleteDialog}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Destek Sil"
        onHide={hideDeleteDialog}
        modal
      >
        <div className="confirmation-content">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>Desteği Silmek İstediğinize Emin Misiniz?</p>
          </div>
          <div className="field">
            <label htmlFor="name" className="font-bold">
              Müşteri Adı: {getCustomerName(selectedTask)}
            </label>
          </div>
          <div className="field">
            <label htmlFor="description" className="font-bold">
              Konu : {selectedTask?.department}
            </label>
          </div>
          <div className="field">
            <label className="mb-3 font-bold">
              Açıklama: {selectedTask?.desc}
            </label>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="Hayır"
              onClick={hideDeleteDialog}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label="Evet"
              onClick={handleDeletedSupport}
              className="btn-excel-two"
            />
          </div>
        </div>
      </Dialog>
    </Flex>
  );
}
