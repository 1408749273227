import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import moneyType from "../../../../../_ekdizayn/api/currency-type.json";
import { InputTextarea } from "primereact/inputtextarea";
import { useToast } from "@chakra-ui/react";
import { UpdateServices } from "../../../../core/Services";
export default function UpdateDialog({
  updateServices,
  setUpdateServices,
  form,
  setForm,
  onChangeInput,
  selectedId,
  setSelectedId,
}) {
  const hideDialog = () => {
    setUpdateServices(false);
    setForm(null);
    setSelectedId("");
  };
  const toast = useToast();

  const handleSave = () => {
    UpdateServices(selectedId, form)
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Veri  Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {
          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
          setForm(null);
          setSelectedId("");
          setUpdateServices(false);
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };
  const addButton = () => {
    return (
      <Button
        onClick={handleSave}
        style={{ width: "9rem" }}
        label="Kaydet"
        className="btn-excel-two"
      />
    );
  };

  return (
    <Dialog
      visible={updateServices}
      footer={addButton}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      style={{ width: "60vw" }}
      header="Hizmet Güncelleme"
      onHide={hideDialog}
      modal
    >
      <div
        className="confirmation-content "
        style={{
          display: "flex",
        }}
      >
        <div className="col-6" style={{ display: "inline-block" }}>
          <small className="font-bold">Hizmet Adı</small>
          <InputText
            name="servicesName"
            type="text"
            onChange={onChangeInput}
            value={form?.servicesName}
            className="p-inputtext-lg w-full"
            placeholder="Hizmet Adı"
          />
          <div
            style={{
              marginTop: "1rem",
            }}
          >
            <small className="font-bold">Hizmet Tipi</small>
            <InputText
              name="servicesType"
              type="text"
              onChange={onChangeInput}
              value={form?.servicesType}
              className="p-inputtext-lg w-full"
              placeholder="Hizmet Türü"
            />
          </div>
          <div
            style={{
              marginTop: "1rem",
            }}
          >
            <small className="font-bold">KDV Oranı(%)</small>
            <InputText
              name="vatRate"
              type="text"
              onChange={onChangeInput}
              value={form?.vatRate}
              className="p-inputtext-lg w-full"
              placeholder="KDV Oranı"
            />
          </div>
          <div
            style={{
              marginTop: "1rem",
            }}
          >
            <small className="font-bold">Alış Fiyatı</small>
            <InputText
              type="text"
              name="buying"
              value={form?.buying}
              onChange={onChangeInput}
              className="p-inputtext-lg w-full"
              placeholder="Alış Fiyatı"
            />
          </div>
          <div className="font-bold mt-2">
            <small>Açıklama:</small>
            <InputTextarea
              value={form?.description}
              name="description"
              placeholder="Açıklama"
              onChange={onChangeInput}
              rows={5}
              cols={30}
            />
          </div>
        </div>
        <div className="col-6" style={{ display: "inline-block" }}>
          <small className="font-bold">Stok:</small>
          <InputText
            type="text"
            name="stock"
            value={form?.stock}
            className="p-inputtext-lg w-full"
            placeholder="Stok"
            onChange={onChangeInput}
          />
          <div
            style={{
              marginTop: "1rem",
            }}
          >
            <small className="font-bold">Para Tipi:</small>
            <Dropdown
              filter
              value={form?.currency}
              name="currency"
              onChange={onChangeInput}
              options={moneyType}
              placeholder="Para Birimi"
              className="w-full "
              style={{
                border: "1px solid gray",
              }}
            />
          </div>
          <div
            style={{
              marginTop: "0.3rem",
            }}
          >
            <small className="font-bold">Satış Fiyatı: </small>
            <InputText
              type="text"
              name="sales"
              value={form?.sales}
              onChange={onChangeInput}
              className="p-inputtext-lg w-full"
              placeholder="Satış Fiyatı"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}
