import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  AddSmsPersonService,
  SmsPersonsService,
} from "../../../../core/Services";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useToast } from "@chakra-ui/react";
export default function Persons() {
  const [persons, setPersons] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [group, setGroup] = useState("");
  const [desc, setDesc] = useState("");
  const toast = useToast();
  const hideDialog = () => {
    setAddModal(false);
    setName("");
    setSurname("");
    setPhone("");
    setTitle("");
    setGroup("");
    setDesc("");
  };
  useEffect(() => {
    SmsPersonsService()
      .then((data) => {
        setPersons(data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, [addModal]);

  const createNewPerson = () => {
    if (name.length === 0 || surname.length === 0 || phone.length <= 9) {
      toast({
        title: "Hata!",
        description: "Lütfen alanları doldurunuz",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }

    AddSmsPersonService(name, surname, phone, title, group, desc).then(
      (res) => {
        if (res?.status === 201) {
          toast({
            title: "Başarılı!",
            description: "Kişi başarıyla eklendi.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          toast({
            title: "Hata!",
            description: "Ekleme işlemi başarısız.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      }
    );
    hideDialog();
  };

  return (
    <Card p={2} className="w-full">
      <CardHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">Rehber</Heading>
          <Button
            label="Kişi Ekle"
            onClick={() => setAddModal(true)}
            className="btn-excel-two"
            style={{
              marginTop: "1rem",
            }}
          />
        </Flex>
      </CardHeader>
      <DataTable
        stripedRows
        size="small"
        value={persons}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        rows={15}
        rowsPerPageOptions={[15, 30, 60, 100]}
        scrollable
        paginator
        selectionMode="single"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate=" {totalRecords} duyurudan {first} - {last} arası gösteriliyor"
      >
        <Column field="name" header="İsim"></Column>
        <Column field="surname" header="Soy İsim" />
        <Column field="phone" header="Numara"></Column>
        <Column field="group" header="Grup" />
        <Column field="title" header="Ünvan" />
        <Column field="desc" header="Açıklama" />
      </DataTable>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Kişi Ekle"
        onHide={hideDialog}
        modal
      >
        <div className="confirmation-content row">
          <div className="col-4" style={{ display: "inline-block" }}>
            <label className="pl-2">İsim</label>
            <InputText
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="İsim"
            />
          </div>
          <div className="col-4" style={{ display: "inline-block" }}>
            <label className="pl-2">Soy İsim</label>
            <InputText
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Soy İsim"
            />
          </div>
          <div className="col-4" style={{ display: "inline-block" }}>
            <label className="pl-2">Telefon Numarası</label>
            <InputGroup>
              <InputLeftAddon>+90</InputLeftAddon>
              <Input
                type="tel"
                placeholder="Numara giriniz"
                value={phone}
                onChange={(e) => {
                  const inputNumber = e.target.value.replace(/\D/g, ""); // Sadece rakam karakterlerini al
                  const trimmedNumber = inputNumber.slice(0, 10); // İlk 10 rakamı al
                  setPhone(trimmedNumber);
                }}
              />
              <div style={{ width: "1rem" }} />
            </InputGroup>
          </div>
        </div>
        <div className="confirmation-content row mt-1">
          <div className="col-4" style={{ display: "inline-block" }}>
            <label className="pl-2">Ünvan</label>
            <InputText
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Ünvan"
            />
          </div>
          <div className="col-4" style={{ display: "inline-block" }}>
            <label className="pl-2">Grup</label>
            <InputText
              value={group}
              onChange={(e) => setGroup(e.target.value)}
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Grup"
            />
          </div>
          <div className="col-4" style={{ display: "inline-block" }}>
            <label className="pl-2">Açıklama</label>
            <InputText
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Açıklama"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            label="Ekle"
            onClick={createNewPerson}
            className="btn-excel-two"
          />
        </div>
      </Dialog>
    </Card>
  );
}
