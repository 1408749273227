import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  OfferService,
  AddOfferService,
  EditOfferService,
  OfferContentService,
  GetOrderService,
} from "../../../../core/Services";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex, Heading, Link } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { BreadCrumb } from "primereact/breadcrumb";
import { Tag } from "primereact/tag";
import imageFooter from "../images/image-footer.png";

import { InputTextarea } from "primereact/inputtextarea";
import { useAuth } from "../../../../auth/core/AuthContext";
export default function Offers() {
  const [customers, setCustomers] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [cust, setCust] = useState("");
  const [offers, setOffers] = useState("");
  const [noteEdit, setNoteEdit] = useState("");
  const [noteTwoEdit, setNoteTwoEdit] = useState("");
  const [dialogs, setDialogs] = useState({});
  const [dialogsPdf, setDialogsPdf] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [notOne, setNoteOne] = useState("");
  const [notTwo, setNoteTwo] = useState("");
  const [subject, setSubject] = useState("");
  const [statusTaxKdv, setStatusTaxKdv] = useState("");
  const [temp, setTemp] = useState("");
  const [products, setProducts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedProd, setSelectedProd] = useState([]);
  const [statuses] = useState([0, 1, 10, 20]);
  const [currencyStatus] = useState(["₺", "€", "$"]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [KDVTax, setKDV] = useState("");
  const [currencyPdf, setCurrencyPdf] = useState("₺");
  const toast = useRef(null);
  const { userData } = useAuth();
  const [inputErrors, setInputErrors] = useState({
    cust: false,
    statusTaxKdv: false,
    subject: false,
    temp: false,
    notOne: false,
    notTwo: false,
  });
  const hideDialog = () => {
    setAddModal(false);
    setDialogs(false);
    setDialogsPdf(false);
    setSelectedData(false);
    setCust("");
    setNoteOne("");
    setNoteTwo("");
    setSubject("");
    setStatusTaxKdv("");
    setTemp("");
    setSelectedProd([]);
  };
  const getOfferData = async (reqBody) => {
    await OfferContentService(reqBody).then((data) => {
      setSelectedProd(data);
    });
  };
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("tr-TR", options);
  };
  const modalContentRef = useRef(null);
  const accessors = (rowData) => {
    const editModal = dialogs[rowData.id] || false;
    // const visible = dialogsDelete[rowData.id] || false;
    const pdfModal = dialogsPdf[rowData.id] || false;

    const printModalContent = () => {
      const content = modalContentRef.current.innerHTML;
      const printWindow = window.open("", "", "width=800,height=800");
      printWindow.document.write(`
        <html>
          <head>
            <style>
              body {
                margin: 0;
                padding: 0;
                font-family: Arial, sans-serif;
                font-size: 12px;
              }
              #footer {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
              }
              @media print {
                body {
                  size: A4;
                }
              }
            </style>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `);
      printWindow.print();
      printWindow.close();
    };
    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#f39f5a" }}>
          <Button
            severity="warning"
            onClick={() => {
              setSelectedData(rowData);
              setSubject(rowData?.subject);
              setDialogs((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true, // Görevin görünürlük state'ini true yap
              }));
              let reqBody = {
                id: rowData.offer_no,
              };
              getOfferData(reqBody);
            }}
            icon="pi pi-pencil"
          />
        </div>

        <div style={{ color: "#1d1a39" }}>
          <Button
            icon="pi pi-bars"
            onClick={() => {
              setSelectedData(rowData);
              setDialogsPdf((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true, // Görevin görünürlük state'ini true yap
              }));
              let reqBody = {
                id: rowData.offer_no,
              };
              getOfferData(reqBody);
            }}
            severity="#1d1a39"
          />
        </div>

        <Dialog
          visible={editModal}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "100vw", height: "100vh" }}
          header="Teklifi Düzenle"
          onHide={hideDialog}
          modal
          footer={
            <Button
              label="Kaydet"
              onClick={() => saveChanges(selectedData.offer_no)}
              className="btn-excel-two"
            />
          }
        >
          <div
            className="confirmation-content "
            style={{
              display: "flex",
            }}
          >
            <div className="col-3" style={{ display: "inline-block" }}>
              <strong>Müşteri</strong>
              <Dropdown
                filter
                options={
                  customers && customers?.length > 0
                    ? customers?.map((dep) => ({
                        label: dep.title,
                        value: dep.id,
                      }))
                    : []
                }
                value={cust}
                placeholder={
                  selectedData.customer_id
                    ? customers?.find(
                        (customer) =>
                          customer.id.toString() === selectedData.customer_id
                      )?.title || "Bilinmeyen"
                    : "Bilinmeyen"
                }
                onChange={(e) => {
                  setCust(e.target.value);
                }}
                style={{
                  border: "1px solid gray",
                  width: "100%",
                }}
              />
            </div>
            <div className="col-3" style={{ display: "inline-block" }}>
              <strong>KDV</strong>
              <Dropdown
                options={statusTax}
                placeholder={
                  selectedData.tax_status
                    ? statusTax.find(
                        (st) => st.value === selectedData.tax_status
                      )?.label || "Bilinmeyen"
                    : "Bilinmeyen"
                }
                value={statusTaxKdv}
                onChange={(e) => {
                  setStatusTaxKdv(e.target.value);
                }}
                style={{
                  border: "1px solid gray",
                  width: "100%",
                }}
              />
            </div>
            <div className="col-3" style={{ display: "inline-block" }}>
              <strong>Konu</strong>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                defaultValue={selectedData.subject}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                style={{ height: "3.15rem" }}
              />
            </div>
            <div className="col-3" style={{ display: "inline-block" }}>
              <strong>Şablon</strong>
              <Dropdown
                filter
                options={
                  templates && templates.length > 0
                    ? templates?.map((dep) => ({
                        label: dep.name,
                        value: dep.id,
                      }))
                    : []
                }
                placeholder={
                  selectedData.template_id
                    ? templates.find(
                        (st) =>
                          st.id.toString() ===
                          selectedData.template_id.toString()
                      )?.name || "Bilinmeyen"
                    : "Bilinmeyen"
                }
                value={temp}
                onChange={(e) => {
                  setTemp(e.target.value);
                }}
                style={{
                  border: "1px solid gray",
                  width: "100%",
                }}
              />
            </div>
          </div>{" "}
          <div
            className="col-12"
            style={{ display: "inline-block", marginBottom: "1rem" }}
          >
            <strong>Not</strong>

            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              defaultValue={selectedData.note}
              onChange={(e) => setNoteEdit(e.target.value)}
            />
            <strong>Açıklama</strong>
            <InputTextarea
              rows={5}
              cols={30}
              type="text"
              defaultValue={selectedData.description}
              className="p-inputtext-lg w-full"
              style={{ height: "6rem" }}
              placeholder="Açıklama"
              onChange={(e) => setNoteTwoEdit(e.target.value)}
            />
          </div>
          <DataTable
            value={selectedProd}
            editMode="row"
            size="small"
            dataKey="unique_id"
            onRowEditComplete={onRowEditComplete}
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column
              body={(rowData) => {
                const cust = products?.find(
                  (dept) => dept?.product_no === rowData?.product_id
                );
                if (cust) {
                  return cust?.name;
                } else {
                  return "Bilinmeyen";
                }
              }}
              header="Ürün"
              style={{ width: "20%" }}
            ></Column>
            <Column
              style={{ width: "10%" }}
              field="amount"
              body={(rowData) => (rowData.amount === 0 ? 1 : rowData.amount)}
              header="Miktar"
              editor={(options) => textEditorQuanEdit(options)}
            ></Column>
            <Column
              body={(rowData) => {
                const cust = products?.find(
                  (dept) => dept?.product_no === rowData?.product_id
                );
                if (cust) {
                  return cust?.unit;
                } else {
                  return "Bilinmeyen";
                }
              }}
              header="Birim"
              style={{ width: "10%" }}
            ></Column>
            <Column
              style={{ width: "10%" }}
              field="price"
              header="Fiyat"
              editor={(options) => textEditorPriceEdit(options)}
            ></Column>
            <Column
              field="tax_percent"
              style={{ width: "10%" }}
              header="KDV (%)"
              editor={(options) => statusEditorEdit(options)}
            ></Column>
            <Column
              field={(rowData) => {
                return Number(rowData.price) * Number(rowData.amount);
              }}
              style={{ width: "10%" }}
              header="Tutar (KDV Hariç)"
            ></Column>
            <Column
              field="description"
              style={{ width: "14%" }}
              header="Açıklama"
              editor={(options) => descEditorEdit(options)}
            ></Column>
            <Column
              rowEditor={allowEdit}
              style={{ width: "8%" }}
              bodyStyle={{ textAlign: "center" }}
            ></Column>
            <Column
              header="Sil"
              style={{ width: "8%" }}
              body={(rowData) => {
                return (
                  <Button
                    icon="pi pi-trash"
                    className="p-button-danger"
                    onClick={() => onDeleteProduct(rowData)}
                  />
                );
              }}
            ></Column>
          </DataTable>
          <Dropdown
            filter
            placeholder="Ürün seçiniz"
            onChange={(e) => {
              onProductSelectEdit(e.target.value);
            }}
            options={
              products && products?.length > 0
                ? products?.map((dep) => ({
                    label: dep.name,
                    value: dep,
                  }))
                : []
            }
            style={{
              padding: "0rem",
              border: "1px solid gray",
              width: "22%",
            }}
          />
          <Link href="/products">
            <Button
              label="Ekle"
              style={{ height: "3.2rem", marginLeft: "1rem" }}
              className="btn-excel-two"
            />
          </Link>
        </Dialog>

        <Dialog
          visible={pdfModal}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "60vw", height: "100vh" }}
          header="Pdf'e Dönüştür"
          onHide={hideDialog}
          modal
        >
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="Siparişe Dönüştür"
              onClick={() => {
                GetOrderService(selectedData?.offer_no, selectedProd);
                hideDialog();
              }}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label="Dönüştür"
              onClick={printModalContent}
              className="btn-excel-two"
            />
          </div>
          <div ref={modalContentRef}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <h2>FİYAT TEKLİFİ</h2>
            </div>

            {selectedData?.customer_id &&
              customers?.find(
                (customer) =>
                  customer?.id?.toString() === selectedData?.customer_id
              ) && (
                <Image
                  width="200"
                  src={`${
                    customers.find(
                      (customer) =>
                        customer?.id?.toString() === selectedData?.customer_id
                    )?.image
                  }`}
                  alt="Selected Image"
                />
              )}
            <div
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h3 style={{ marginTop: "0.5rem" }}>
                  {selectedData?.customer_id
                    ? customers.find(
                        (customer) =>
                          customer.id.toString() === selectedData?.customer_id
                      )?.title || "Bilinmeyen"
                    : "Bilinmeyen"}
                </h3>
                <h3 style={{ marginTop: "0.5rem" }}>
                  V.K.N:{" "}
                  {selectedData.customer_id
                    ? customers.find(
                        (customer) =>
                          customer.id.toString() === selectedData?.customer_id
                      )?.tax_number || "Bilinmeyen"
                    : "Bilinmeyen"}{" "}
                  {selectedData.customer_id
                    ? customers.find(
                        (customer) =>
                          customer.id.toString() === selectedData.customer_id
                      )?.tax_administration || "Bilinmeyen"
                    : "Bilinmeyen"}
                </h3>

                <h3 style={{ marginTop: "0.5rem" }}>
                  {" "}
                  Adres:{" "}
                  {selectedData.customer_id
                    ? customers.find(
                        (customer) =>
                          customer.id.toString() === selectedData.customer_id
                      )?.address || "Bilinmeyen"
                    : "Bilinmeyen"}
                </h3>
                <h3 style={{ marginTop: "0.5rem" }}>
                  {" "}
                  Tel:{" "}
                  {selectedData.customer_id
                    ? customers.find(
                        (customer) =>
                          customer.id.toString() === selectedData.customer_id
                      )?.phone || "Bilinmeyen"
                    : "Bilinmeyen"}
                </h3>
                <h3 style={{ marginTop: "0.5rem" }}>
                  {" "}
                  E-Posta:{" "}
                  {selectedData.customer_id
                    ? customers.find(
                        (customer) =>
                          customer.id.toString() === selectedData.customer_id
                      )?.phone || "Bilinmeyen"
                    : "Bilinmeyen"}
                </h3>
              </div>

              <div>
                <h3 style={{ marginTop: "0.5rem" }}>
                  {" "}
                  Teklif No: {selectedData.offer_no}
                </h3>
                <h3 style={{ marginTop: "0.5rem" }}>
                  {" "}
                  Teklif Tarihi: {formatDate(selectedData.date)}
                </h3>
                <h3 style={{ marginTop: "0.5rem" }}>
                  {" "}
                  Teklif Veren: {selectedData?.employee}
                </h3>
              </div>
            </div>
            <div style={{ paddingLeft: "1rem" }}>
              <h3 style={{ marginTop: "0.5rem" }}>{rowData.note} </h3>
            </div>
            <div style={{ padding: "1rem", width: "100%" }}>
              {" "}
              <DataTable
                value={selectedProd}
                size="small"
                dataKey="id"
                style={{ width: "100%" }}
              >
                <Column
                  body={(rowData) => {
                    const cust = products.find(
                      (dept) => dept.product_no === rowData.product_id
                    );
                    if (cust) {
                      return cust.product_no;
                    } else {
                      return "Bilinmeyen";
                    }
                  }}
                  header="Ürün"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  body={(rowData) => {
                    const cust = products.find(
                      (dept) => dept.product_no === rowData.product_id
                    );
                    if (cust) {
                      return cust.name;
                    } else {
                      return "Bilinmeyen";
                    }
                  }}
                  header="Ürün"
                ></Column>
                <Column field="amount" header="Miktar"></Column>
                <Column
                  body={(rowData) => {
                    const cust = products.find(
                      (dept) => dept.product_no === rowData.product_id
                    );
                    if (cust) {
                      return cust.unit;
                    } else {
                      return "Bilinmeyen";
                    }
                  }}
                  header="Birim"
                ></Column>
                <Column field="price" header="Fiyat"></Column>
                <Column
                  field="tax_percent"
                  style={{ width: "10%" }}
                  header="KDV (%)"
                ></Column>
                <Column
                  field={(rowData) =>
                    Number(rowData.price) * Number(rowData.amount)
                  }
                  header="Tutar (KDV Hariç)"
                />
                <Column
                  field="description"
                  style={{ width: "15%" }}
                  header="Açıklama"
                ></Column>
              </DataTable>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ paddingLeft: "1rem", marginTop: "2rem" }}>
                <h3 style={{ marginTop: "0.5rem" }}>Açıklama;</h3>

                <h3 style={{ marginTop: "0.5rem" }}>{rowData.description} </h3>
              </div>
              <div
                style={{
                  paddingLeft: "1rem",
                  marginTop: "2rem",
                  paddingRight: "3rem",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <h3 style={{ marginTop: "0.5rem" }}>Ara Toplam: </h3>{" "}
                  <h3 style={{ marginTop: "0.5rem" }}>
                    {totalAmount} {currencyPdf}
                  </h3>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <h3 style={{ marginTop: "0.5rem" }}>İndirim: </h3>{" "}
                  <h3 style={{ marginTop: "0.5rem" }}>0.00{currencyPdf}</h3>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <h3 style={{ marginTop: "0.5rem" }}>Toplam: </h3>{" "}
                  <h3 style={{ marginTop: "0.5rem" }}>
                    {totalAmount} {currencyPdf}
                  </h3>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <h3 style={{ marginTop: "0.5rem" }}>KDV: </h3>{" "}
                  <h3 style={{ marginTop: "0.5rem" }}>% {KDVTax}</h3>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3 style={{ marginTop: "1rem" }}>Genel Toplam: </h3>
                  <h3 style={{ marginTop: "1rem" }}>
                    {(totalAmount * KDVTax) / 100 + totalAmount} {currencyPdf}
                  </h3>
                </div>
              </div>
            </div>
            <div
              id="footer"
              style={{
                flex: "0 0 auto",
                paddingRight: "1.5rem",
                paddingLeft: "1.5rem",
                paddingBottom: "1.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "30%", textAlign: "left" }}>
                  <p>Müşteri</p>
                  <div
                    style={{
                      height: "100px",
                      border: "1px solid black",
                      boxSizing: "border-box",
                    }}
                  ></div>
                </div>
                <div style={{ width: "30%", textAlign: "left" }}>
                  <p>Firma</p>
                  <div
                    style={{
                      height: "100px",
                      border: "1px solid black",
                      boxSizing: "border-box",
                    }}
                  ></div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image src={imageFooter} alt="Image" width="500" />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };

  const saveChanges = async (id) => {
    let requestBody = {
      id,
      cust,
      statusTaxKdv,
      subject,
      temp,
      noteEdit,
      noteTwoEdit,
      selectedProd,
      user_id: userData?.id,
    };
    await EditOfferService(requestBody);
    hideDialog();
  };

  const newCreate = async () => {
    if (!cust || !statusTaxKdv || !subject || !temp || !notOne || !notTwo) {
      setInputErrors({
        cust: !cust,
        statusTaxKdv: !statusTaxKdv,
        subject: !subject,
        temp: !temp,
        notOne: !notOne,
        notTwo: !notTwo,
      });
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen boş kısımları doldurun.",
      });
      return;
    }
    let requestBody = {
      cust,
      statusTaxKdv,
      subject,
      temp,
      note: notOne,
      desc: notTwo,
      selectedProd,
      user_id: userData?.id,
    };
    await AddOfferService(requestBody);
    setAddModal(false);
  };

  useEffect(() => {
    OfferService().then((data) => {
      setOffers(data?.offers);
      setCustomers(data?.customers);
      setProducts(data?.products);
      setTemplates(data?.templates);
    });
  }, [addModal]);

  useEffect(() => {
    let total = 0;
    if (Array.isArray(selectedProd)) {
      selectedProd.forEach((rowData) => {
        const totalPrice = Number(rowData?.price) * Number(rowData?.amount);
        total += totalPrice;
        setKDV(rowData?.tax_percent);
        setCurrencyPdf(rowData?.currency);
      });
    } else {
      console.error("selectedProd is not an array.");
    }

    if (selectedData.tax_status === "1") {
      setKDV(0);
    }
    setTotalAmount(total);
  }, [selectedData, selectedProd]);

  const getTurkishLabel = (url) => {
    switch (url) {
      case "/offers":
        return "Teklifler";
      // Diğer durumlar için gerektiğinde eşleme yapabilirsiniz
      default:
        return "Sayfa"; // Varsayılan olarak "Sayfa" kullanılabilir veya istediğiniz bir şeyi kullanabilirsiniz
    }
  };
  const url = "/offers";
  const turkishLabel = getTurkishLabel(url); // "Ürünler"

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };

  const onRowEditComplete = (e) => {
    let _products = [...products];
    let { newData, index } = e;

    _products[index] = newData;

    setProducts(_products);
  };

  const textEditorQuan = (options) => {
    const handleInputChange = (e) => {
      options.editorCallback(e.target.value);
    };

    const handleInputBlur = () => {
      const updatedSelectedProd = selectedProd?.map((item) =>
        item.unique_id === options.rowData.unique_id
          ? { ...item, offer_quantity: options.value }
          : item
      );
      setSelectedProd(updatedSelectedProd);
    };

    return (
      <InputText
        type="text"
        value={options.value}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        style={{ padding: "0.4rem" }}
      />
    );
  };
  const textEditorPrice = (options) => {
    const handleInputChange = (e) => {
      // input değerini güncelle
      options.editorCallback(e.target.value);
    };

    const handleInputBlur = () => {
      const updatedSelectedProd = selectedProd?.map((item) =>
        item.unique_id === options.rowData.unique_id
          ? { ...item, sale_price: options.value }
          : item
      );
      setSelectedProd(updatedSelectedProd);
    };

    return (
      <InputText
        type="text"
        value={options.value}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        style={{ padding: "0.4rem" }}
      />
    );
  };
  const textEditorQuanEdit = (options) => {
    const handleInputChange = (e) => {
      options.editorCallback(e.target.value);
    };

    const handleInputBlur = () => {
      const updatedSelectedProd = selectedProd?.map((item) =>
        item.unique_id === options.rowData.unique_id
          ? { ...item, quantity: options.value, amount: options.value }
          : item
      );
      setSelectedProd(updatedSelectedProd);
    };

    return (
      <InputText
        type="text"
        value={options.value}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        style={{ padding: "0.4rem" }}
      />
    );
  };
  const textEditorPriceEdit = (options) => {
    const handleInputChange = (e) => {
      options.editorCallback(e.target.value);
    };

    const handleInputBlur = () => {
      const updatedSelectedProd = selectedProd?.map((item) =>
        item.unique_id === options.rowData.unique_id
          ? { ...item, sale_price: options.value, price: options.value }
          : item
      );
      setSelectedProd(updatedSelectedProd);
    };

    return (
      <InputText
        type="text"
        value={options.value}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        style={{ padding: "0.4rem" }}
      />
    );
  };
  const allowEdit = (rowData) => {
    return rowData.name !== "";
  };
  const onDeleteProduct = (rowData) => {
    const productId = rowData.unique_id;
    const newSelectedProd = selectedProd.filter(
      (prod) => prod.unique_id !== productId
    );
    setSelectedProd(newSelectedProd);
  };
  const onProductSelect = (e) => {
    const unique_id = new Date().getTime();
    const offer_quantity = 1;
    const updatedProduct = { ...e, unique_id, offer_quantity };
    const newSelectedProd = [...selectedProd, updatedProduct];
    setSelectedProd(newSelectedProd);
  };

  const onProductSelectEdit = (e) => {
    const unique_id = new Date().getTime();
    const offer_quantity = 1;
    const newE = {
      ...e,
      unique_id: unique_id,
      product_id: e.product_no,
      amount: e.quantity,
      price: e.sale_price,
      tax_percent: e.tax_percentage,
      offer_quantity,
    };
    const newSelectedProd = [...selectedProd, newE];
    setSelectedProd(newSelectedProd);
  };
  const statusTax = [
    { label: "KDV Dahil", value: "0" },
    { label: "KDV Hariç", value: "1" },
  ];

  const currencyEditor = (options) => {
    const handleInputChange = (e) => {
      options.editorCallback(e.target.value);
    };

    const handleInputBlur = () => {
      const updatedSelectedProd = selectedProd?.map((item) =>
        item.unique_id === options.rowData.unique_id
          ? { ...item, currency: options.value }
          : item
      );
      setSelectedProd(updatedSelectedProd);
    };

    return (
      <Dropdown
        value={options.value}
        options={currencyStatus}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        placeholder="Para Birimi"
        itemTemplate={(option) => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };
  const descEditor = (options) => {
    const handleInputChange = (e) => {
      options.editorCallback(e.target.value);
    };

    const handleInputBlur = () => {
      const updatedSelectedProd = selectedProd?.map((item) =>
        item.unique_id === options.rowData.unique_id
          ? { ...item, description: options.value }
          : item
      );
      setSelectedProd(updatedSelectedProd);
    };

    return (
      <InputTextarea
        value={options.value}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        placeholder="Açıklama"
        style={{ padding: "0.4rem", height: "3rem" }}
      />
    );
  };
  const descEditorEdit = (options) => {
    const handleInputChange = (e) => {
      // input değerini güncelle
      options.editorCallback(e.target.value);
    };

    const handleInputBlur = () => {
      const updatedSelectedProd = selectedProd?.map((item) =>
        item.unique_id === options.rowData.unique_id
          ? {
              ...item,
              description: options.value,
            }
          : item
      );
      setSelectedProd(updatedSelectedProd);
    };
    return (
      <InputTextarea
        value={options.value}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        placeholder="Açıklama"
        style={{ padding: "0.4rem", height: "3rem" }}
      />
    );
  };
  const statusEditor = (options) => {
    const handleInputChange = (e) => {
      options.editorCallback(e.target.value);
    };

    const handleInputBlur = () => {
      const updatedSelectedProd = selectedProd?.map((item) =>
        item.unique_id === options.rowData.unique_id
          ? { ...item, tax_percentage: options.value }
          : item
      );
      setSelectedProd(updatedSelectedProd);
    };

    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        placeholder="KDV"
        itemTemplate={(option) => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };
  const statusEditorEdit = (options) => {
    const handleInputChange = (e) => {
      options.editorCallback(e.target.value);
    };

    const handleInputBlur = () => {
      const updatedSelectedProd = selectedProd?.map((item) =>
        item.unique_id === options.rowData.unique_id
          ? {
              ...item,
              tax_percentage: options.value,
              tax_percent: options.value,
            }
          : item
      );
      setSelectedProd(updatedSelectedProd);
    };
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        placeholder="KDV"
        itemTemplate={(option) => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <BreadCrumb
            model={itemsBread}
            home={home}
            style={{ padding: "1rem" }}
          />
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Teklifler</Heading>
              <div>
                <Button
                  label="Yeni"
                  onClick={() => setAddModal(true)}
                  className="btn-excel-two"
                />
              </div>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />

          <DataTable
            stripedRows
            value={offers}
            size="small"
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} tekliften {first} - {last} arası gösteriliyor"
          >
            <Column
              style={{ width: "8rem" }}
              field="accessor"
              body={accessors}
              header="İşlemler"
            ></Column>
            <Column field="offer_no" header="Teklif No"></Column>
            <Column
              field="title"
              header="Müşteri"
              body={(rowData) => {
                const cust = customers.find(
                  (dept) => dept.id.toString() === rowData.customer_id
                );
                if (cust) {
                  const firstTwoWords =
                    cust.title && cust.title.split(" ").slice(0, 2).join(" ");
                  return firstTwoWords;
                } else {
                  return "Bilinmeyen";
                }
              }}
            ></Column>
            <Column field="subject" header="Konu"></Column>

            <Column
              field="state"
              body={(rowData) => {
                if (rowData.state === "1") {
                  return "Sipariş";
                } else {
                  return "Bekleyen";
                }
              }}
              header="Durum"
            ></Column>
            <Column field="employee" header="Teklif Veren"></Column>
            <Column
              field="date"
              body={(rowData) => formatDate(rowData.date)}
              header="Tarih"
            ></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "100vw", height: "100vh" }}
        header="Teklif Oluştur"
        onHide={hideDialog}
        modal
      >
        <div
          className="confirmation-content "
          style={{
            display: "flex",
          }}
        >
          <div className="col-3" style={{ display: "inline-block" }}>
            <strong>Müşteri</strong>
            <Dropdown
              filter
              options={
                customers && customers?.length > 0
                  ? customers?.map((dep) => ({
                      label: dep.title,
                      value: dep.id,
                    }))
                  : []
              }
              value={cust}
              placeholder="Müşteri Adı / Ünvanı"
              onChange={(e) => {
                setCust(e.target.value);
                setInputErrors({ ...inputErrors, cust: false });
              }}
              style={{
                border: "1px solid gray",
                width: "100%",
              }}
            />
            {inputErrors.cust && (
              <small style={{ color: "red" }}>Lütfen bir müşteri seçin.</small>
            )}
          </div>
          <div className="col-3" style={{ display: "inline-block" }}>
            <strong>KDV</strong>
            <Dropdown
              options={statusTax}
              placeholder="KDV"
              value={statusTaxKdv}
              onChange={(e) => {
                setStatusTaxKdv(e.target.value);
                setInputErrors({ ...inputErrors, statusTaxKdv: false });
              }}
              style={{
                border: "1px solid gray",
                width: "100%",
              }}
            />
            {inputErrors.statusTaxKdv && (
              <small style={{ color: "red" }}>Lütfen KDV seçin.</small>
            )}
          </div>
          <div className="col-3" style={{ display: "inline-block" }}>
            <strong>Konu</strong>
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Konu"
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
                setInputErrors({ ...inputErrors, subject: false });
              }}
              style={{ height: "3.15rem" }}
            />
            {inputErrors.subject && (
              <small style={{ color: "red" }}>Lütfen bir konu girin.</small>
            )}
          </div>
          <div className="col-3" style={{ display: "inline-block" }}>
            <strong>Şablon</strong>
            <Dropdown
              filter
              options={
                templates && templates?.length > 0
                  ? templates?.map((dep) => ({
                      label: dep.name,
                      value: dep.id,
                    }))
                  : []
              }
              placeholder="Şablon"
              value={temp}
              onChange={(e) => {
                setTemp(e.target.value);
                setInputErrors({ ...inputErrors, temp: false });
              }}
              style={{
                border: "1px solid gray",
                width: "100%",
              }}
            />
            {inputErrors.temp && (
              <small style={{ color: "red" }}>Lütfen bir şablon seçin.</small>
            )}
          </div>
        </div>
        <div
          className="col-12"
          style={{ display: "inline-block", marginBottom: "1rem" }}
        >
          <div>
            <strong>Not</strong>
            <br />
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Not"
              onChange={(e) => {
                setNoteOne(e.target.value);
                setInputErrors({ ...inputErrors, notOne: false });
              }}
            />
            {inputErrors.notOne && (
              <small style={{ color: "red" }}>Lütfen bir not girin.</small>
            )}
          </div>
          <div>
            <strong>Açıklama</strong>
            <InputTextarea
              rows={5}
              cols={30}
              type="text"
              className="p-inputtext-lg w-full"
              style={{ height: "6rem" }}
              placeholder="Açıklama"
              onChange={(e) => {
                setNoteTwo(e.target.value);
                setInputErrors({ ...inputErrors, notTwo: false });
              }}
            />
            {inputErrors.notTwo && (
              <small style={{ color: "red" }}>Lütfen açıklama not girin.</small>
            )}
          </div>
        </div>
        <DataTable
          value={selectedProd}
          editMode="row"
          size="small"
          dataKey="unique_id"
          onRowEditComplete={onRowEditComplete}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="name" header="Ürün" style={{ width: "15%" }}></Column>
          <Column
            style={{ width: "10%" }}
            field="offer_quantity"
            header="Miktar"
            editor={(options) => textEditorQuan(options)}
          ></Column>
          <Column field="unit" header="Birim" style={{ width: "7%" }}></Column>
          <Column
            style={{ width: "10%" }}
            field="sale_price"
            header="Fiyat"
            editor={(options) => textEditorPrice(options)}
          ></Column>
          <Column
            field="tax_percentage"
            style={{ width: "14%" }}
            header="KDV (%)"
            editor={(options) => statusEditor(options)}
          ></Column>
          <Column
            field="currency"
            style={{ width: "12%" }}
            header="Para Birimi"
            editor={(options) => currencyEditor(options)}
          ></Column>
          <Column
            field={(rowData) => {
              return (
                Number(rowData.sale_price) * Number(rowData.offer_quantity)
              );
            }}
            style={{ width: "10%" }}
            header="Tutar (KDV Hariç)"
          ></Column>
          <Column
            field="description"
            style={{ width: "15%" }}
            header="Açıklama"
            editor={(options) => descEditor(options)}
          ></Column>
          <Column
            rowEditor={allowEdit}
            style={{ width: "8%" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
          <Column
            header="Sil"
            style={{ width: "5%" }}
            body={(rowData) => {
              return (
                <Button
                  icon="pi pi-trash"
                  className="p-button-danger"
                  onClick={() => onDeleteProduct(rowData)}
                />
              );
            }}
          ></Column>
        </DataTable>
        <Dropdown
          filter
          placeholder="Ürün seçiniz"
          onChange={(e) => {
            onProductSelect(e.target.value);
          }}
          options={
            products && products?.length > 0
              ? products?.map((dep) => ({
                  label: dep.name,
                  value: dep,
                }))
              : []
          }
          style={{
            padding: "0rem",
            border: "1px solid gray",
            width: "22%",
          }}
        />

        <Link href="/products">
          <Button
            label="Ekle"
            style={{ height: "3.2rem", marginLeft: "1rem" }}
            className="btn-excel-two"
          />
        </Link>

        <div style={{ marginTop: "2rem" }}></div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
          }}
        >
          <Button
            label="Oluştur"
            onClick={newCreate}
            className="btn-excel-two"
          />
        </div>
      </Dialog>
    </Flex>
  );
}
