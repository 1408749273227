import { Card } from "primereact/card";
import React from "react";
import { Link } from "react-router-dom";

export default function SettingsPage() {
  return (
    <div
      className="confirmation-content"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <div className="col-6" style={{ display: "inline-block" }}>
        <Card
          style={{
            marginTop: "0.8rem",
            opacity: "0.7",
            textAlign: "right",
            backgroundColor: "#e2e2e2",
            height: "7rem",
          }}
          title="Temel Ayarlar"
          subTitle="Görüntüle"
          className="md:w-25rem"
        />

        <Link to="/departments">
          <Card
            style={{
              marginTop: "0.8rem",
              textAlign: "right",
              backgroundColor: "#e2e2e2",
              height: "7rem",
            }}
            title="Departman Ayarları"
            subTitle="Görüntüle"
            className="md:w-25rem"
          />
        </Link>
        <Link to="/settings/data-transfer">
          <Card
            style={{
              marginTop: "0.8rem",

              textAlign: "right",
              backgroundColor: "#e2e2e2",
              height: "7rem",
            }}
            title="Veri Aktarımı"
            subTitle="Görüntüle"
            className="md:w-25rem"
          />
        </Link>

        <Card
          style={{
            marginTop: "0.8rem",
            opacity: "0.7",
            textAlign: "right",
            backgroundColor: "#e2e2e2",
            height: "7rem",
          }}
          title="Portal Ayarları"
          subTitle="Görüntüle"
          className="md:w-25rem"
        />

        <Card
          style={{
            marginTop: "0.8rem",
            opacity: "0.7",
            textAlign: "right",
            backgroundColor: "#e2e2e2",
            height: "7rem",
          }}
          title="Şablon Ayarları"
          subTitle="Görüntüle"
          className="md:w-25rem"
        />
      </div>

      <div className="p-col-6" style={{ display: "inline-block" }}>
        <Link to="/user/settings">
          <Card
            style={{
              marginTop: "0.8rem",
              textAlign: "right",
              backgroundColor: "#e2e2e2",
              height: "7rem",
            }}
            title="Kullanıcı Ayarları"
            subTitle="Görüntüle"
            className="md:w-25rem"
          />
        </Link>
        <Card
          style={{
            marginTop: "0.8rem",
            opacity: "0.7",
            textAlign: "right",
            backgroundColor: "#e2e2e2",
            height: "7rem",
          }}
          title="Parametre Ayarları"
          subTitle="Görüntüle"
          className="md:w-25rem"
        />

        <Card
          style={{
            marginTop: "0.8rem",
            opacity: "0.7",
            textAlign: "right",
            backgroundColor: "#e2e2e2",
            height: "7rem",
          }}
          title="Özel Alanlar"
          subTitle="Görüntüle"
          className="md:w-25rem"
        />

        <Card
          style={{
            marginTop: "0.8rem",
            opacity: "0.7",
            textAlign: "right",
            backgroundColor: "#e2e2e2",
            height: "7rem",
          }}
          title="Bildirim Ayarları"
          subTitle="Görüntüle"
          className="md:w-25rem"
        />
        <Card
          style={{
            marginTop: "0.8rem",
            opacity: "0.7",
            textAlign: "right",
            backgroundColor: "#e2e2e2",
            height: "7rem",
          }}
          title="Entegrasyon ayarları"
          subTitle="Görüntüle"
          className="md:w-25rem"
        />
      </div>
    </div>
  );
}
