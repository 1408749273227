import React, { useState, useEffect } from "react";

import { AddCustomerSupport } from "../../../../core/Services";
import { Button } from "primereact/button";
import { Input } from "@chakra-ui/react";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";

export default function CustomerSupportFom({ customer }) {
  const [dep, setDep] = useState("");
  const [cus, setCus] = useState("");
  const [desc, setDesc] = useState("");
  const [customerUniqueId, setCustomerUniqueId] = useState("");
  const [taskStatus, setTaskStatus] = useState("");
  const isButtonDisable = false;

  const statusType = [
    { label: "Normal", value: "1" },
    { label: "Öncelikli", value: "0" },
    { label: "Kritik", value: "2" },
  ];
  const subjects = [
    { label: "Destek", value: "Destek" },
    { label: "Satış", value: "Satış" },
    { label: "Muhasebe", value: "Muhasebe" },
    { label: "Şikayet", value: "Şikayet" },
    { label: "Diğer", value: "Diğer" },
  ];
  const newCreate = async () => {
    await AddCustomerSupport(
      cus,
      desc,
      dep,
      taskStatus,
      customerUniqueId
    );

    setDesc("");
    setDep("");
    setTaskStatus("");
   window.location.reload()
    
  };

  useEffect(() => {
    setCus(customer.title);
    setCustomerUniqueId(customer.unique_id);
   
  }, [customer]);

  return (
    <div>
      <div className="confirmation-content">
        <div className="p-grid p-fluid">
          <div className="col-sm-12">
            <div style={{ display: "flex", marginBottom: "1rem" }}>
              <Input
                value={cus}
                onChange={(e)=> setCus(e.target.value)}
                style={{
                  border: "1px solid gray",
                  width: "100%",
                }}
                placeholder="Ad-Soyad"
              />
            </div>
          </div>
          <div className="p-col-6" style={{ marginBottom: "1rem" }}>
            <Dropdown
              filter
              options={subjects}
              value={dep}
              placeholder="Konu"
              onChange={(e) => setDep(e.target.value)}
              style={{
                border: "1px solid gray",
                marginBottom: "1rem",
                width: "100%",
              }}
            />
          </div>
          <div className="p-col-6" style={{ marginBottom: "1rem" }}>
            <Dropdown
              filter
              options={statusType}
              placeholder="Durum"
              value={taskStatus}
              onChange={(e) => setTaskStatus(e.value)}
              style={{
                border: "1px solid gray",
                width: "100%",
                marginBottom: "1rem",
              }}
            />
          </div>
          <div className="p-col-6" style={{ marginBottom: "1rem" }}>
            <InputTextarea
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Açıklama"
              onChange={(e) => setDesc(e.target.value)}
              value={desc}
              autoResize
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button
          label="Ekle"
          disabled={isButtonDisable}
          onClick={newCreate}
          style={{ width: "15%" }}
          className="btn-excel-two"
        />
      </div>
    </div>
  );
}
