import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  ProductService,
  AddProductService,
  EditProductService,
  DeleteProductService,
} from "../../../../core/Services";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card, CardHeader, Flex, Heading } from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { BreadCrumb } from "primereact/breadcrumb";
import { Image } from "primereact/image";

export default function Product() {
  const getTurkishLabel = (url) => {
    switch (url) {
      case "/products":
        return "Ürünler";
      default:
        return "Sayfa";
    }
  };

  const url = "/products";
  const turkishLabel = getTurkishLabel(url);
  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  const [products, setProducts] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [productsNo, setProductsNo] = useState("");
  const [taxPercent, setTaxPercent] = useState("");
  const [currency, setCurrency] = useState("");
  const [brand, setBrand] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [newName, setNewName] = useState("");
  const [newQuantity, setNewQuantity] = useState("");
  const [newPurchasePrice, setNewPurchasePrice] = useState("");
  const [newBrand, setNewBrand] = useState("");
  const [newTaxPercent, setNewTaxPercent] = useState("");
  const [newSalePrice, setNewSalePrice] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const [dialogs, setDialogs] = useState({});
  const [dialogsDelete, setDialogsDelete] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [newCurrency, setNewCurrency] = useState("");
  const [unit, setUnit] = useState("");
  const toast = useRef(null);
  const [image, setImage] = useState("");
  const [inputErrors, setInputErrors] = useState({
    name: false,
    quantity: false,
    unit: false,
    purchasePrice: false,
    currency: false,
    brand: false,
    taxPercent: false,
    salePrice: false,
  });
  const saveChanges = async (id) => {
    let requestBody = {
      id,
      name: newName,
      quantity: newQuantity,
      purchasePrice: newPurchasePrice,
      currency: newCurrency,
      brand: newBrand,
      taxPercent: newTaxPercent,
      salePrice: newSalePrice,
      image,
    };

    await EditProductService(requestBody);
    setDialogs(false);
  };

  const newCreate = async () => {
    if (
      !name ||
      !quantity ||
      !unit ||
      !purchasePrice ||
      !currency ||
      !brand ||
      !taxPercent ||
      !salePrice
    ) {
      setInputErrors({
        name: !name,
        quantity: !quantity,
        unit: !unit,
        purchasePrice: !purchasePrice,
        currency: !currency,
        brand: !brand,
        taxPercent: !taxPercent,
        salePrice: !salePrice,
      });
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen boş kısımları doldurun.",
      });
      return;
    }

    let requestBody = {
      name,
      quantity,
      taxPercent,
      currency,
      brand,
      purchasePrice,
      salePrice,
      unit,
      image,
    };
    await AddProductService(requestBody);
    setAddModal(false);
    setName("");
    setCurrency("");
    setTaxPercent("");
    setQuantity("");
    setPurchasePrice("");
    setSalePrice("");
    setBrand("");
  };

  const hideDialog = () => {
    setAddModal(false);
    setDialogs(false);
    setProductsNo("");
    setName("");
    setDialogsDelete(false);
    setSelectedData(false);
  };

  const accessors = (rowData) => {
    const editModal = dialogs[rowData.id] || false;
    const visible = dialogsDelete[rowData.id] || false;

    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#f39f5a" }}>
          <Button
            severity="warning"
            onClick={() => {
              setSelectedData(rowData);
              setDefaultValue(rowData.currency);
              setNewBrand(rowData?.brand);
              setNewCurrency(rowData?.currency);
              setNewName(rowData?.name);
              setNewPurchasePrice(rowData?.purchase_price);
              setNewQuantity(rowData?.quantity);
              setNewSalePrice(rowData?.sale_price);
              setNewTaxPercent(rowData?.tax_percentage);
              setDialogs((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
            }}
            icon="pi pi-pencil"
          />
        </div>
        <div style={{ color: "#ae445a" }}>
          <Button
            icon="pi pi-trash"
            onClick={() => {
              setSelectedData(rowData);

              setDialogsDelete((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setName(rowData?.name);
              setProductsNo(rowData?.product_no);
            }}
            severity="danger"
          />
        </div>
        <Dialog
          header="Ürünü silmek istediğinize emin misiniz?"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={hideDialog}
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={hideDialog}
                className="btn-excel-two"
              />
              <Button
                label="Ürünü Sil"
                icon="pi pi-check"
                onClick={() => {
                  DeleteProductService(selectedData.id);
                  hideDialog();
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Ürün Numarası: {productsNo}
              </label>
            </div>
            <div className="field">
              <label htmlFor="name" className="font-bold">
                İsim: {name}
              </label>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={editModal}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ width: "60vw" }}
          header="Ürünü Düzenle"
          onHide={hideDialog}
          modal
        >
          <div
            className="confirmation-content "
            style={{
              display: "flex",
            }}
          >
            <div className="col-6" style={{ display: "inline-block" }}>
              <label>İsim</label>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="İsim"
                onChange={(e) => setNewName(e.target.value)}
                defaultValue={selectedData.name}
              />
              <label>Birim Adedi</label>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Birim Adedi"
                onChange={(e) => setNewQuantity(e.target.value)}
                defaultValue={selectedData.quantity}
              />
              <label>Alış Fiyatı</label>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Alış Fiyatı"
                onChange={(e) => setNewPurchasePrice(e.target.value)}
                defaultValue={selectedData.purchase_price}
              />
              <Dropdown
                filter
                options={currencyType}
                placeholder="Para Birimi"
                value={newCurrency}
                className="w-full "
                onChange={(e) => setNewCurrency(e.value)}
                style={{
                  border: "1px solid gray",
                  marginTop: "1rem",
                }}
              />
              <Image
                src={`${process.env.REACT_APP_BASE_ENDPOINT}/${selectedData?.image}`}
                alt="Image"
                width="100"
              />
            </div>
            <div className="col-6" style={{ display: "inline-block" }}>
              <label>Marka</label>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Marka"
                onChange={(e) => setNewBrand(e.target.value)}
                defaultValue={selectedData.brand}
              />
              <label>KDV Oranı (%)</label>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="KDV Oranı (%)"
                onChange={(e) => setNewTaxPercent(e.target.value)}
                defaultValue={Number(selectedData.tax_percentage)}
              />
              <label>Satış Fiyatı</label>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Satış Fiyatı"
                onChange={(e) => setNewSalePrice(e.target.value)}
                defaultValue={selectedData.sale_price}
              />
              <h6 className="pt-3">Ürün Fotoğrafı (.png)</h6>
              <input
                type="file"
                name="file"
                accept=".png"
                onChange={handleFile}
                style={{ display: "block" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "1rem",
                }}
              >
                <Button
                  label="Kaydet"
                  onClick={() => saveChanges(selectedData.id)}
                  className="btn-excel-two"
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };

  const currencyType = [
    { label: "TL", value: "0" },
    { label: "Dolar", value: "1" },
    { label: "Euro", value: "2" },
  ];

  const handleFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setImage(base64String);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setNewCurrency(defaultValue);

    ProductService().then((data) => setProducts(data));
  }, [addModal, selectedData, dialogs, defaultValue]);

  const workingBodyTemplate = (rowData) => {
    const getStatusLabel = (currency) => {
      switch (currency) {
        case "0":
          return "Türk Lirası";

        case "1":
          return "Dolar";

        case "2":
          return "Euro";

        default:
          return null;
      }
    };

    const statusLabel = getStatusLabel(rowData.currency);

    return <Tag value={statusLabel} severity={getSeverity(rowData)}></Tag>;
  };
  const getSeverity = (data) => {
    switch (data.currency) {
      case "0":
        return "info";

      case "1":
        return "success";

      case "2":
        return "danger";

      default:
        return null;
    }
  };
  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <BreadCrumb
          model={itemsBread}
          home={home}
          style={{ padding: "1rem" }}
        />
        <Card>
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Ürünler</Heading>

              <div>
                <Button
                  label="Yeni"
                  onClick={() => setAddModal(true)}
                  className="btn-excel-two"
                />
              </div>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />
          <DataTable
            stripedRows
            value={products}
            size="small"
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollable
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} üründen {first} - {last} arası gösteriliyor"
          >
            <Column
              style={{ width: "8rem" }}
              field="accessor"
              body={accessors}
              header="İşlemler"
            ></Column>
            <Column field="product_no" header="Ürün Kodu"></Column>
            <Column field="name" header="Ürün Adı"></Column>
            <Column field="quantity" header="Stok"></Column>
            <Column
              field="tax_percentage"
              header="KDV Oranı (%)"
              body={(rowData) => Number(rowData?.tax_percentage)}
            ></Column>

            <Column
              field="currency"
              body={workingBodyTemplate}
              header="Para Birimi"
            ></Column>
            <Column field="purchase_price" header="Alış Fiyatı"></Column>
            <Column field="sale_price" header="Satış Fiyatı"></Column>
            <Column field="brand" header="Marka"></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Ürün Ekle"
        onHide={hideDialog}
        modal
      >
        <div
          className="confirmation-content "
          style={{
            display: "flex",
          }}
        >
          <div className="col-6" style={{ display: "inline-block" }}>
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="İsim"
              onChange={(e) => {
                setName(e.target.value);
                setInputErrors({ ...inputErrors, name: false });
              }}
              value={name}
            />
            {inputErrors.name && (
              <small style={{ color: "red" }}>Lütfen bir isim girin.</small>
            )}
            <div style={{ display: "flex" }}>
              {" "}
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Birim Adedi"
                onChange={(e) => {
                  setQuantity(e.target.value);
                  setInputErrors({ ...inputErrors, quantity: false });
                }}
                value={quantity}
                style={{
                  marginTop: "1rem",
                }}
              />
              <div style={{ width: "1rem" }} />
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder="Birim Türü"
                onChange={(e) => {
                  setUnit(e.target.value);
                  setInputErrors({ ...inputErrors, unit: false });
                }}
                value={unit}
                style={{
                  marginTop: "1rem",
                }}
              />
            </div>
            {inputErrors.quantity && (
              <small style={{ color: "red" }}>Lütfen adet girin.</small>
            )}
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Alış Fiyatı"
              onChange={(e) => {
                setPurchasePrice(e.target.value);
                setInputErrors({ ...inputErrors, purchasePrice: false });
              }}
              value={purchasePrice}
              style={{
                marginTop: "1rem",
              }}
            />
            {inputErrors.purchasePrice && (
              <small style={{ color: "red" }}>Lütfen alış fiyatı girin.</small>
            )}
            <Dropdown
              filter
              options={currencyType}
              placeholder="Para Birimi"
              value={currency}
              className="w-full "
              onChange={(e) => {
                setCurrency(e.value);
                setInputErrors({ ...inputErrors, currency: false });
              }}
              style={{
                border: "1px solid gray",
                marginTop: "1rem",
              }}
            />
            {inputErrors.currency && (
              <small style={{ color: "red" }}>
                Lütfen para birimi seçiniz.
              </small>
            )}
          </div>
          <div className="col-6" style={{ display: "inline-block" }}>
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Marka"
              onChange={(e) => {
                setBrand(e.target.value);
                setInputErrors({ ...inputErrors, brand: false });
              }}
              value={brand}
            />
            {inputErrors.brand && (
              <small style={{ color: "red" }}>Lütfen marka giriniz.</small>
            )}
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="KDV Oranı (%)"
              onChange={(e) => {
                setTaxPercent(e.target.value);
                setInputErrors({ ...inputErrors, taxPercent: false });
              }}
              value={taxPercent}
              style={{
                marginTop: "1rem",
              }}
            />
            {inputErrors.taxPercent && (
              <small style={{ color: "red" }}>Lütfen KDV oranı giriniz.</small>
            )}
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Satış Fiyatı"
              onChange={(e) => {
                setSalePrice(e.target.value);
                setInputErrors({ ...inputErrors, salePrice: false });
              }}
              value={salePrice}
              style={{
                marginTop: "1rem",
              }}
            />
            {inputErrors.salePrice && (
              <small style={{ color: "red" }}>
                Lütfen satış fiyatı giriniz.
              </small>
            )}
            <h6 className="pt-3">Ürün Fotoğrafı (.png)</h6>
            <input
              type="file"
              name="file"
              accept=".png"
              onChange={handleFile}
              style={{ display: "block" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "1rem",
              }}
            >
              <Button
                label="Ekle"
                onClick={newCreate}
                className="btn-excel-two"
              />
            </div>
          </div>
        </div>
      </Dialog>
    </Flex>
  );
}
