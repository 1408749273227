import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  CustomerService,
  AddCustomerService,
  EditCustomerService,
  DeleteCustomerService,
} from "../../../../core/Services";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {
  Card,
  CardHeader,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { BreadCrumb } from "primereact/breadcrumb";

export default function CustomerList() {
  const [customers, setCustomers] = useState([]);
  const [oldCustomers, setOldCustomers] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [name, setName] = useState("");
  const [keyWords, setKeyWords] = useState("");
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [site, setSite] = useState("");
  const [taxAdmin, setTaxAdmin] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState("");
  const [mail, setMail] = useState("");
  const [productsNo, setProductsNo] = useState("");
  const [image, setImage] = useState("");
  const [dialogs, setDialogs] = useState({});
  const [dialogsDelete, setDialogsDelete] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const toast = useRef(null);
  const [inputErrors, setInputErrors] = useState({
    name: false,
    title: false,
    phone: false,
    site: false,
    taxAdmin: false,
    type: false,
    address: false,
    taxNumber: false,
    mail: false,
  });

  const hideDialog = () => {
    setAddModal(false);
    setProductsNo("");
    setDialogs(false);
    setDialogsDelete(false);
    setSelectedData(false);
    setImage("");
    setName("");
    setTitle("");
    setTaxAdmin("");
    setTaxNumber("");
    setType("");
    setAddress("");
    setPhone("");
    setMail("");
    setSite("");
    setKeyWords("");
  };

  const accessors = (rowData) => {
    const editModal = dialogs[rowData.id] || false;
    const visible = dialogsDelete[rowData.id] || false;
    return (
      <div style={{ display: "flex" }}>
        <div style={{ color: "#f39f5a" }}>
          <Button
            severity="warning"
            onClick={() => {
              setSelectedData(rowData);

              setDialogs((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
              setName(rowData?.name);
              setTitle(rowData?.title);
              setPhone(rowData?.phone);
              setMail(rowData?.email);
              setSite(rowData?.website);
              setTaxAdmin(rowData?.tax_administration);
              setTaxNumber(rowData?.tax_number);
              setType(rowData?.type);
              setAddress(rowData?.address);
              setKeyWords(rowData?.key_words);
            }}
            icon="pi pi-pencil"
          />
        </div>
        <div style={{ color: "#ae445a" }}>
          <Button
            icon="pi pi-trash"
            onClick={() => {
              setSelectedData(rowData);
              setProductsNo(rowData?.customer_code);
              setName(rowData.title);
              setMail(rowData.email);

              setDialogsDelete((prevDialogs) => ({
                ...prevDialogs,
                [rowData.id]: true,
              }));
            }}
            severity="danger"
          />
        </div>
        <Dialog
          header="Müşteriyi silmek istediğinize emin misiniz?"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={hideDialog}
          footer={
            <div>
              <Button
                label="İptal"
                icon="pi pi-times"
                onClick={hideDialog}
                className="btn-excel-two"
              />
              <Button
                label="Müşteriyi Sil"
                icon="pi pi-check"
                onClick={() => {
                  DeleteCustomerService(selectedData.id);
                  hideDialog();
                }}
                className="btn-excel-two"
                style={{ marginLeft: "15px" }}
              />
            </div>
          }
        >
          <div className="confirmation-content">
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Müşteri Numarası: {productsNo}
              </label>
            </div>
            <div className="field">
              <label htmlFor="name" className="font-bold">
                Müşteri Adı: {name}
              </label>
            </div>
            <div className="field">
              <label htmlFor="description" className="font-bold">
                Email : {mail}
              </label>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={editModal}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          style={{ overflowX: "hidden" }}
          header={
            rowData?.customer_code
              ? `Müşteri Düzenle (${rowData.customer_code})`
              : "Müşteri Düzenle"
          }
          onHide={hideDialog}
          modal
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div className="col-4  " style={{ display: "inline-block" }}>
              <p>Ad Soyad:</p>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder={rowData.name}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <p>Ünvan:</p>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder={rowData.title}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
              <p>Telefon Numarası:</p>
              <InputGroup>
                <InputLeftAddon>+90</InputLeftAddon>
                <Input
                  type="tel"
                  border="1px gray solid"
                  placeholder={rowData.phone}
                  value={phone}
                  onChange={(e) => {
                    const inputNumber = e.target.value.replace(/\D/g, ""); // Sadece rakam karakterlerini al
                    const trimmedNumber = inputNumber.slice(0, 10); // İlk 10 rakamı al
                    setPhone(trimmedNumber);
                  }}
                />
              </InputGroup>
            </div>
            <div className="col-4  " style={{ display: "inline-block" }}>
              <p>Web Sitesi:</p>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder={rowData.website}
                onChange={(e) => setSite(e.target.value)}
                value={site}
              />
              <p>Vergi Dairesi:</p>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder={rowData.tax_administration}
                onChange={(e) => setTaxAdmin(e.target.value)}
                value={taxAdmin}
              />
              <p>Müşteri Tipi:</p>
              <Dropdown
                filter
                options={customerType}
                placeholder={rowData.customer_type}
                value={type}
                className="w-full "
                onChange={(e) => setType(e.value)}
                style={{
                  border: "1px solid gray",
                }}
              />
            </div>
            <div className="col-4" style={{ display: "inline-block" }}>
              <p>Adres:</p>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder={rowData.address}
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
              <p>Vergi Numarası:</p>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder={rowData.tax_number}
                onChange={(e) => setTaxNumber(e.target.value)}
                value={taxNumber}
              />
              <div className="pt-3">
                <h6>Logo (.png)</h6>
                <input
                  type="file"
                  name="file"
                  accept=".png"
                  onChange={handleFile}
                  style={{ display: "block" }}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="col-4">
              <p>Email:</p>
              <InputText
                type="text"
                className="p-inputtext-lg w-full"
                placeholder={rowData.email}
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                style={{
                  border: "1px solid gray",
                }}
              />{" "}
            </div>
            <div className="col-8">
              <p>Anahtar Kelimeler (Virgül ile ayırınız!):</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <InputText
                  type="text"
                  className="p-inputtext-lg w-full"
                  placeholder={rowData.key_words}
                  onChange={(e) => setKeyWords(e.target.value)}
                  value={keyWords}
                  style={{
                    border: "1px solid gray",
                  }}
                />
                <div style={{ width: "1rem" }} />
                <Button
                  label="Kaydet"
                  onClick={() => saveChanges(rowData.id)}
                  className="btn-excel-two"
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };
  const saveChanges = async (id) => {
    let cusType;
    if (type === "1") {
      cusType = "Kurumsal";
    } else if (type === "0") {
      cusType = "Bireysel";
    }

    let requestBody = {
      id,
      name,
      title,
      phone,
      site,
      email: mail,
      taxAdmin,
      taxNumber,
      address,
      type: cusType,
      image,
      keyWords,
    };
    await EditCustomerService(requestBody);
    setDialogs(false);
  };
  const newCreate = async () => {
    if (
      !name ||
      !title ||
      !phone ||
      !site ||
      !taxAdmin ||
      !type ||
      !address ||
      !taxNumber ||
      !mail
    ) {
      setInputErrors({
        name: !name,
        title: !title,
        phone: !phone,
        site: !site,
        taxAdmin: !taxAdmin,
        type: !type,
        address: !address,
        taxNumber: !taxNumber,
        mail: !mail,
      });
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen boş kısımları doldurun.",
      });
      return;
    }

    let cusType;
    if (type === "1") {
      cusType = "Kurumsal";
    } else {
      cusType = "Bireysel";
    }

    let requestBody = {
      name,
      title,
      phone,
      mail,
      site,
      taxAdmin,
      taxNumber,
      address,
      type: cusType,
      image,
      keyWords,
    };
    await AddCustomerService(requestBody);
    setAddModal(false);
    setName("");
    setTitle("");
    setPhone("");
    setMail("");
    setSite("");
    setTaxAdmin("");
    setTaxNumber("");
    setAddress("");
    setType("");
  };

  const customerType = [
    { label: "Bireysel", value: "0" },
    { label: "Kurumsal", value: "1" },
  ];

  useEffect(() => {
    CustomerService().then((data) => {
      setCustomers(data);
      setOldCustomers(data);
    });
  }, [addModal, selectedData, dialogs]);
  const getTurkishLabel = (url) => {
    switch (url) {
      case "/customer-list":
        return "Müşteriler";
      // Diğer durumlar için gerektiğinde eşleme yapabilirsiniz
      default:
        return "Sayfa"; // Varsayılan olarak "Sayfa" kullanılabilir veya istediğiniz bir şeyi kullanabilirsiniz
    }
  };
  const url = "/customer-list";
  const turkishLabel = getTurkishLabel(url); // "Ürünler"

  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };

  const handleFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setImage(base64String);
    };

    reader.readAsDataURL(file);
  };

  const filterCustomer = (value) => {
    let matchingObjects = [];
    const lowerCaseValue = value.toLowerCase();

    if (value === "") {
      setCustomers(oldCustomers);
    } else {
      if (Array.isArray(customers) && customers?.length > 0) {
        matchingObjects = customers?.filter(
          (item) =>
            item?.name?.toLowerCase().includes(lowerCaseValue) ||
            item?.title?.toLowerCase().includes(lowerCaseValue) ||
            item?.key_words?.toLowerCase().includes(lowerCaseValue)
        );
      }
      setCustomers(matchingObjects);
    }
  };

  return (
    <Flex mt="5" justifyContent="center" alignItems="center">
      <div
        className="card"
        style={{
          overflow: "auto",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          position: "relative",
          padding: "1rem",
        }}
      >
        <Card>
          <BreadCrumb
            model={itemsBread}
            home={home}
            style={{ padding: "1rem" }}
          />
          <CardHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Müşteri</Heading>
              <div style={{ display: "flex" }}>
                <span
                  className="p-input-icon-right"
                  style={{
                    border: "solid 1px gray",
                    borderRadius: "6px 6px 6px 6px",
                    padding: "0.2rem",
                  }}
                >
                  <i className="pi pi-search" />
                  <InputText
                    placeholder="Müşteri Ara"
                    onChange={(e) => filterCustomer(e.target.value)}
                  />
                </span>
                <div style={{ width: "1rem" }} />
                <Button
                  label="Yeni"
                  onClick={() => setAddModal(true)}
                  className="btn-excel-two"
                />
              </div>
            </Flex>
          </CardHeader>
          <Toast ref={toast} />

          <DataTable
            stripedRows
            size="small"
            value={customers}
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
            rows={15}
            rowsPerPageOptions={[15, 30, 60, 100]}
            scrollable
            scrollHeight="60vh"
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate=" {totalRecords} müşteriden {first} - {last} arası gösteriliyor"
          >
            <Column
              style={{ width: "8rem" }}
              field="accessor"
              body={accessors}
              header="İşlemler"
            ></Column>
            <Column field="customer_code" header="Müşteri Kodu"></Column>
            <Column field="title" header="Ad Soyad / Ünvan"></Column>
            <Column field="address" header="Adres"></Column>
            <Column field="email" header="Email"></Column>
            <Column field="phone" header="Telefon"></Column>
            <Column field="customer_type" header="Müşteri Tipi"></Column>
            <Column field="tax_administration" header="Vergi Dairesi"></Column>
            <Column field="tax_number" header="Vergi Numarası"></Column>
            <Column field="key_words" header="Anahtar Kelimeler"></Column>
          </DataTable>
        </Card>
      </div>
      <Dialog
        visible={addModal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Müşteri Ekle"
        onHide={hideDialog}
        modal
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div className="col-4  " style={{ display: "inline-block" }}>
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Ad Soyad"
              onChange={(e) => {
                setName(e.target.value);
                setInputErrors({ ...inputErrors, name: false });
              }}
              value={name}
            />
            {inputErrors.name && (
              <small style={{ color: "red" }}>Lütfen ad-soyad giriniz.</small>
            )}
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Ünvan"
              onChange={(e) => {
                setTitle(e.target.value);
                setInputErrors({ ...inputErrors, title: false });
              }}
              value={title}
              style={{
                marginTop: "1rem",
              }}
            />
            {inputErrors.title && (
              <small style={{ color: "red" }}>Lütfen ünvan giriniz.</small>
            )}
            <InputGroup paddingTop="1rem">
              <InputLeftAddon>+90</InputLeftAddon>
              <Input
                type="tel"
                border="1px gray solid"
                placeholder="Numara giriniz"
                value={phone}
                onChange={(e) => {
                  const inputNumber = e.target.value.replace(/\D/g, ""); // Sadece rakam karakterlerini al
                  const trimmedNumber = inputNumber.slice(0, 10); // İlk 10 rakamı al
                  setPhone(trimmedNumber);
                  setInputErrors({ ...inputErrors, phone: false });
                }}
              />
            </InputGroup>
            {inputErrors.phone && (
              <small style={{ color: "red" }}>Lütfen numara giriniz.</small>
            )}
          </div>
          <div className="col-4  " style={{ display: "inline-block" }}>
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Web Sitesi"
              onChange={(e) => {
                setSite(e.target.value);
                setInputErrors({ ...inputErrors, site: false });
              }}
              value={site}
            />
            {inputErrors.site && (
              <small style={{ color: "red" }}>Lütfen web sitesi giriniz.</small>
            )}
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Vergi Dairesi"
              onChange={(e) => {
                setTaxAdmin(e.target.value);
                setInputErrors({ ...inputErrors, taxAdmin: false });
              }}
              value={taxAdmin}
              style={{
                marginTop: "1rem",
              }}
            />
            {inputErrors.taxAdmin && (
              <small style={{ color: "red" }}>
                Lütfen vergi dairesi giriniz.
              </small>
            )}
            <Dropdown
              filter
              options={customerType}
              placeholder="Müşteri Tipi"
              value={type}
              className="w-full "
              onChange={(e) => {
                setType(e.value);
                setInputErrors({ ...inputErrors, type: false });
              }}
              style={{
                border: "1px solid gray",
                marginTop: "1rem",
              }}
            />
            {inputErrors.type && (
              <small style={{ color: "red" }}>
                Lütfen müşteri tipi seçiniz.
              </small>
            )}
          </div>
          <div className="col-4" style={{ display: "inline-block" }}>
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Adres"
              onChange={(e) => {
                setAddress(e.target.value);
                setInputErrors({ ...inputErrors, address: false });
              }}
              value={address}
            />
            {inputErrors.address && (
              <small style={{ color: "red" }}>Lütfen adres giriniz.</small>
            )}
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Vergi Numarası"
              onChange={(e) => {
                setTaxNumber(e.target.value);
                setInputErrors({ ...inputErrors, taxNumber: false });
              }}
              value={taxNumber}
              style={{
                marginTop: "1rem",
              }}
            />
            {inputErrors.taxNumber && (
              <small style={{ color: "red" }}>
                Lütfen vergi numarası giriniz.
              </small>
            )}
            <div className="pt-3">
              <h6>Logo (.png)</h6>
              <input
                type="file"
                name="file"
                accept=".png"
                onChange={handleFile}
                style={{ display: "block" }}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="col-4"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Email"
              onChange={(e) => {
                setMail(e.target.value);
                setInputErrors({ ...inputErrors, mail: false });
              }}
              value={mail}
              style={{
                border: "1px solid gray",
              }}
            />
          </div>

          <div
            className="col-8"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <InputText
              type="text"
              className="p-inputtext-lg w-full"
              placeholder="Anahtar Kelimeler (Virgül ile ayırınız!)"
              onChange={(e) => setKeyWords(e.target.value)}
              value={keyWords}
              style={{
                border: "1px solid gray",
              }}
            />
            <div style={{ width: "1rem" }} />
            <Button
              label="Ekle"
              onClick={newCreate}
              className="btn-excel-two"
            />
          </div>
        </div>
        {inputErrors.mail && (
          <small style={{ color: "red" }}>Lütfen mail adresi giriniz.</small>
        )}
      </Dialog>
    </Flex>
  );
}
