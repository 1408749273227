import React from "react";
import CustomerSupportPage from "./CustomerSupportPage";

export default function CustomerSupport() {
  return (
    <div className="p-1">
    <CustomerSupportPage />
    </div>
  );
}
