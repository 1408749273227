import React, { useEffect, useState } from "react";
import ServicesDatatable from "./components/ServicesDatatable";
import AddDialog from "./components/AddDialog";
import { useToast } from "@chakra-ui/react";
import { AddServices, AllServices } from "../../../core/Services";
import DeleteDialog from "./components/DeleteDialog";
import UpdateDialog from "./components/UpdateDialog";

export default function ServicesPage() {
  const [services, setServices] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [addServices, setAddServices] = useState(false);
  const [updateServices, setUpdateServices] = useState(false);
  const [deleteServices, setDeleteServices] = useState(false);
  const toast = useToast();
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    servicesType: "",
    servicesName: "",
    stock: "",
    vatRate: "",
    currency: "",
    buying: "",
    sales: "",
    description: "",
  });
  const onChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const onSubmitForm = () => {
    const inputErrors = {};
    if (!form?.servicesType) {
      inputErrors.servicesType = "Lütfen hizmet türü giriniz";
    }
    if (!form?.stock) {
      inputErrors.stock = "Lütfen stok giriniz";
    }
    if (!form?.vatRate) {
      inputErrors.vatRate = "Lütfen KDV oranı giriniz";
    }
    if (!form?.currency) {
      inputErrors.currency = "Lütfen para birimi seçiniz";
    }
    if (!form?.buying) {
      inputErrors.buying = "Lütfen alış tutarı  giriniz";
    }
    if (!form?.sales) {
      inputErrors.sales = "Lütfen satış tutarı  giriniz";
    }
    if (!form?.servicesName) {
      inputErrors.servicesName = "Lütfen hizmet ismi   giriniz";
    }
    if (!form?.description) {
      inputErrors.description = "Lütfen açıklama  giriniz";
    }

    if (Object?.keys(inputErrors).length > 0) {
      setErrors(inputErrors);
    } else {
      setErrors({});
      handleSave();
      setForm(null);
      setAddServices(false);
    }
  };

  const getTurkishLabel = (url) => {
    switch (url) {
      case "/services":
        return "Hizmetler";
      default:
        return "Sayfa";
    }
  };
  const url = "/services";
  const turkishLabel = getTurkishLabel(url);
  const itemsBread = [{ label: turkishLabel, url: url }];
  const home = { icon: "pi pi-home", url: "/dashboard" };
  const handleSave = () => {
    AddServices(form)
      .then((response) => {
        if (response.message === "Hata") {
          toast({
            title: "Hata",
            description: "Kullanıcya Ait Veri  Mevcut",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        } else {
          toast({
            title: "Başarılı",
            description: "Veri başarılı bir şekilde kaydedildi.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.error("Backend'e gönderme hatası:", error);
        toast({
          title: "Hata",
          description: "Veri gönderilirken bir hata oluştu.",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      });
  };
  useEffect(() => {
    AllServices()
      .then((data) => {
        setServices(data);
      })
      .catch((error) => {
        console.log("Hizmet Çekme Hatası", error);
      });
  }, []);
  return (
    <>
      <ServicesDatatable
        addServices={addServices}
        setAddServices={setAddServices}
        itemsBread={itemsBread}
        home={home}
        form={form}
        setDeleteServices={setDeleteServices}
        setForm={setForm}
        services={services}
        updateServices={updateServices}
        setUpdateServices={setUpdateServices}
        setServices={setServices}
        setSelectedId={setSelectedId}
      />

      <AddDialog
        onSubmitForm={onSubmitForm}
        errors={errors}
        onChangeInput={onChangeInput}
        form={form}
        setForm={setForm}
        addServices={addServices}
        setAddServices={setAddServices}
      />
      <DeleteDialog
        form={form}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        setForm={setForm}
        deleteServices={deleteServices}
        setDeleteServices={setDeleteServices}
      />
      <UpdateDialog
        onChangeInput={onChangeInput}
        form={form}
        setForm={setForm}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        updateServices={updateServices}
        setUpdateServices={setUpdateServices}
      />
    </>
  );
}
