import React, { useEffect } from "react";
import customerPackages from "../../../../_ekdizayn/api/packages.json";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";

export default function Package({
  packages,
  setPackages,
  selectedPackages,
  setSelectedPackages,
}) {
  useEffect(() => {
    setPackages(customerPackages);
  }, [setPackages]);

  const handlePackageSelect = (pack) => {
    if (!selectedPackages) {
      setSelectedPackages([pack]);
    } else if (selectedPackages.includes(pack)) {
      setSelectedPackages(
        selectedPackages.filter((selectedPack) => selectedPack !== pack)
      );
    } else {
      setSelectedPackages([...selectedPackages, pack]);
    }
  };

  return (
    <div className="grid">
      {packages.map((pack, index) => (
        <div className="col-12 md:col-6 lg:col-3">
          <Card
            header={<img src={pack.src} alt={pack.header} />}
            key={index}
            title={pack.title}
            style={{
              border: "1px solid gray",
              height: "100%",
              textAlign: "center",
              position: "relative", // Kartın içeriğiyle birlikte yerleşimi sağlamak için
            }}
            className=" p-5 "
          >
            <div className="card-content">
              {pack.content.map((cont, i) => (
                <ul
                  style={{ listStyle: "outside" }}
                  className="text-xl text-left"
                  key={i}
                >
                  <li>{cont.substance}</li>
                </ul>
              ))}
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "5px",
                justifyContent: "center",
                display: "flex",
                width: "80%",
                borderRadius: "10px",
                alignItems: "center",
              }}
            >
              <Checkbox
                inputId={`pack-checkbox-${pack.id}`}
                checked={selectedPackages?.includes(pack)}
                onChange={() => handlePackageSelect(pack)}
              />
              <label htmlFor={`pack-checkbox-${pack.id}`} className="text-xl">
                Seç
              </label>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
}
