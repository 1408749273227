import React, { useEffect, useState } from "react";
import { SendSmsService, SmsUserService } from "../../../../core/Services";
import { Button } from "primereact/button";
import {
  Card,
  CardHeader,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { useToast } from "@chakra-ui/react";
import { TreeSelect } from "primereact/treeselect";
import { Dialog } from "primereact/dialog";
export default function Sms(credit) {
  const [checked, setChecked] = useState(true);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedNodeKeys, setSelectedNodeKeys] = useState([]);
  const [guide, setGuide] = useState([]);
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [numberList, setNumberList] = useState([]);
  const toast = useToast();

  const sendSms = () => {
    if (selectedNodeKeys.length === 0 && numberList.length === 0) {
      toast({
        title: "Gönderilemedi!",
        description: "Lütfen geçerli numaralar giriniz.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } else if (message === "") {
      toast({
        title: "Gönderilemedi!",
        description: "Lütfen mesaj giriniz.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      const integerKeys = Object?.keys(selectedNodeKeys)
        ?.filter((key) => Number?.isInteger(Number(key)))
        ?.map((key) => parseInt(key));

      SendSmsService(integerKeys, numberList, message).then((res) => {
        if (res.status === 200) {
          toast({
            title: "Başarıyla Gönderildi!",
            description: "Mesajınız başarıyla ulaştı.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          setMessage("");
          setNumber("");
          setNumberList("");
          setSelectedNodeKeys("");
          window.location.reload();
        } else {
          toast({
            title: "Gönderilemedi!",
            description: "Mesajınızı gönderirken bir hata oluştu.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
      setMessage("");
      setNumber("");
      setNumberList([]);
      setModal(false);
    }
  };

  const addNumber = () => {
    if (number !== "") {
      setNumberList((prevList) => [...prevList, number]);
      setNumber("");
    }
  };

  const nodes = [];

  for (const groupKey in guide) {
    if (guide.hasOwnProperty(groupKey)) {
      const group = guide[groupKey];

      const parentNode = {
        key: groupKey,
        label: groupKey,
        data: "Events Folder",
        icon: "pi pi-fw pi-users",
        children: [],
      };

      group.forEach((person) => {
        const childNode = {
          key: person.id.toString(),
          label: `${person.name} ${person.surname}`,
          icon: "pi pi-fw pi-user",
          data: person.data,
        };
        parentNode.children.push(childNode);
      });

      nodes.push(parentNode);
    }
  }
  useEffect(() => {
    SmsUserService()
      .then((data) => {
        setGuide(data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return (
    <Card p={2} className="w-full">
      <CardHeader>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md">SMS Gönder</Heading>
          <p>
            Kalan Kontör: {credit?.credit?.amount}{" "}
            {credit?.credit?.balance_name}
          </p>
        </Flex>
      </CardHeader>
      <div
        style={{
          display: "flex",
        }}
      >
        {" "}
        <div className="col-4">
          {" "}
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <label>Gruplara</label> <div style={{ width: "1rem" }} />
            <Checkbox
              onChange={(e) => {
                setChecked(true);
                setCheckedTwo(false);
              }}
              checked={checked}
            ></Checkbox>{" "}
            <div style={{ width: "4rem" }} />
            <label>Numaralara </label>
            <div style={{ width: "1rem" }} />
            <Checkbox
              onChange={(e) => {
                setChecked(false);
                setCheckedTwo(true);
              }}
              checked={checkedTwo}
            ></Checkbox>
          </div>
          {checked ? (
            <TreeSelect
              filter
              value={selectedNodeKeys}
              onChange={(e) => setSelectedNodeKeys(e.value)}
              options={nodes}
              metaKeySelection={false}
              style={{
                border: "solid 1px gray",
                marginTop: "1rem",
                maxWidth: "20rem",
              }}
              className=" w-full"
              selectionMode="checkbox"
              display="chip"
              placeholder="Kişileri Seçiniz"
            ></TreeSelect>
          ) : (
            <InputGroup paddingTop="1rem">
              <InputLeftAddon>+90</InputLeftAddon>
              <Input
                type="tel"
                placeholder="Numara giriniz"
                value={number}
                onChange={(e) => {
                  const inputNumber = e.target.value.replace(/\D/g, ""); // Sadece rakam karakterlerini al
                  const trimmedNumber = inputNumber.slice(0, 10); // İlk 10 rakamı al
                  setNumber(trimmedNumber);
                }}
              />
              <div style={{ width: "1rem" }} />
              <Button
                className="btn-excel-two"
                onClick={addNumber}
                disabled={number.length < 10}
                icon="pi pi-plus"
              />
            </InputGroup>
          )}
          <div className="p-4">
            {" "}
            <ul>
              {numberList.map((number, index) => (
                <li key={index} style={{ color: "dimgray" }}>
                  {number}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-8">
          <InputText
            type="text"
            className="p-inputtext-lg w-full"
            placeholder="Başlık"
            value="EK Dizayn"
          />
          <InputTextarea
            type="text"
            rows={5}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            cols={30}
            className="p-inputtext-lg w-full mt-1"
            placeholder="Mesajınız"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "1rem",
        }}
      >
        <Button
          style={{ marginTop: "2rem" }}
          label="SMS Gönder"
          onClick={() => setModal(true)}
          className="btn-excel-two"
        />
      </div>
      <Dialog
        visible={modal}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        style={{ width: "60vw" }}
        header="Sms'i göndermek istediğinize emin misiniz?"
        onHide={() => setModal(false)}
        modal
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              label="İptal"
              onClick={() => setModal(false)}
              className="btn-excel-two"
              style={{ marginRight: "1rem" }}
            />
            <Button
              label="Gönder"
              onClick={sendSms}
              className="btn-excel-two"
            />
          </div>
        }
      ></Dialog>
    </Card>
  );
}
