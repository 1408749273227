import axios from "axios";

//General
const baseEndpoint = process.env.REACT_APP_BASE_ENDPOINT;
const apiPath = process.env.REACT_APP_API_PATH;

//Task
const tasksPath = process.env.REACT_APP_TASKS_PATH || "/tasks";
const finishTasksPath = process.env.REACT_APP_finishTASKS_PATH || "/finishTask";
const addTasksPath = process.env.REACT_APP_addTASKS_PATH || "/addTasks";
const assignTaskPath = process.env.REACT_APP_assignTASKS_PATH || "/assignTask";
const updateTasksPath =
  process.env.REACT_APP_updateTASKS_PATH || "/updateTasks";
const DeleteTaskPath = process.env.REACT_APP_deleteTASKS_PATH || "/deleteTask";

//User
const UserPath = process.env.REACT_APP_AllUser_PATH || "/allUser";
const PostUserPath = process.env.REACT_APP_addUser_PATH || "/addUser";
const EditUserPath = process.env.REACT_APP_editUser_PATH || "/editUser";
const DeleteUserPath = process.env.REACT_APP_deleteUser_PATH || "/deleteUser";
const getUserByUniqueId =
  process.env.REACT_APP_getUserByUniqueId_PATH || "/getUserByUniqueId";
const AddExcelEmployeePath =
  process.env.REACT_APP_addExcelEmployee_PATH || "/addExcelEmployee";
//Permissions
const cancelPermission =
  process.env.REACT_APP_cancelPermission || "/cancelPermission";
const addPermissions =
  process.env.REACT_APP_addPermissions_PATH || "/addPermissions";
const updatePermissionStatus =
  process.env.REACT_APP_updatePermissionStatus || "/updatePermissionStatus";
const allPermissions =
  process.env.REACT_APP_allPermissions_PATH || "/permissions";

// Manufacturing

const ManufacturingPath =
  process.env.REACT_APP_allManufacturing_PATH || "/manufacturing";
const AddManufacturing =
  process.env.REACT_APP_addManufacturing_PATH || "/addManufacturing";
const UpdateManufacturing =
  process.env.REACT_APP_updateManufacturing_PATH || "/updateManufacturing";
const DeleteManufacturing =
  process.env.REACT_APP_deleteManufacturing_PATH || "/deleteManufacturing";
//Customer"
const CustomerPath = process.env.REACT_APP_Customer_PATH || "/customer";
const PostCustomerPath =
  process.env.REACT_APP_addCustomer_PATH || "/addCustomer";
const DeleteCustomerPath =
  process.env.REACT_APP_deleteCustomer_PATH || "/deleteCustomer";
const EditCustomerPath =
  process.env.REACT_APP_editCustomer_PATH || "/editCustomer";
const AddExcelCustomerPath =
  process.env.REACT_APP_addExcelCustomer_PATH || "/addExcelCustomer";
//Departments
const DepartmentPath = process.env.REACT_APP_Department_PATH || "/department";
const AddDepartment =
  process.env.REACT_APP_AddDepartment_PATH || "/addDepartment";
const DeleteDepartmentPath =
  process.env.REACT_APP_DeleteDepartment_PATH || "/deleteDepartment";
const UpdateDepartment =
  process.env.REACT_APP_UpdateDepartment_PATH || "/updateDepartment";

//Entegrations
const EntegrationPath =
  process.env.REACT_APP_Entegration_PATH || "/entegrations";
const savePaymentSettings =
  process.env.REACT_APP_SavePaymentSettings_PATH || "/paymentSettings";
const saveSmsSettings =
  process.env.REACT_APP_SaveSmsSettings_PATH || "/smsSettings";

//TodoList
const getTodoListPath =
  process.env.REACT_APP_getTodoList_PATH || "/getTodoList";
const addTodoPath = process.env.REACT_APP_addTodo_PATH || "/addTodo";
const editTodoPath = process.env.REACT_APP_editTodo_PATH || "/editTodo";
const DeleteTodoPath = process.env.REACT_APP_deleteTodo_PATH || "/deleteTodo";

//Jobs
const jobsPath = process.env.REACT_APP_JOBS_PATH || "/jobs";
const addJobsPath = process.env.REACT_APP_addJOBS_PATH || "/addJobs";
const assignJobsPath = process.env.REACT_APP_assignJOBS_PATH || "/assignJobs";
const finishJobsPath = process.env.REACT_APP_finishJobs_PATH || "/finishJobs";
const EditJobPath = process.env.REACT_APP_editJob_PATH || "/editJob";
const DeleteJobPath = process.env.REACT_APP_deleteJob_PATH || "/deleteJob";
const JobDetailsPath = process.env.REACT_APP_JobDetails_PATH || "/jobDetails";
const AddStagePath = process.env.REACT_APP_AddStage_PATH || "/addStage";
const EditStagePath = process.env.REACT_APP_EditStage_PATH || "/editStage";
const DeleteStagePath =
  process.env.REACT_APP_DeleteStage_PATH || "/deleteStage";
//Tracks
const AddTracks =
  process.env.REACT_APP_AddTracks_PATH || "/addDomainNameTracks";
const AddTime = process.env.REACT_APP_AddTime_PATH || "/addTime";
const UpdateTracks =
  process.env.REACT_APP_updateTrack_PATH || "/updateHostingTracks";
const DeleteTracks =
  process.env.REACT_APP_deleteTrack_PATH || "/deleteDomainNameTracks";
const UpdateServiceTracks =
  process.env.REACT_APP_updateServiceTrack_PATH || "/updateServiceTracks";
const ModernizeTrack =
  process.env.REACT_APP_modernizeTrack_PATH || "/modernizeDomainNameTracks";
const Tracks = process.env.REACT_APP_Tracks_PATH || "/tracks";
const DeleteHostingTracks =
  process.env.REACT_APP_deleteHostingTrack_PATH || "/deleteHostingTracks";
const DeleteServiceTracks =
  process.env.REACT_APP_deleteServiceTrack_PATH || "/deleteServiceTracks";
const VisibileDomainNameTracks =
  process.env.REACT_APP_visibileDomainNameTracks_PATH ||
  " /visibileDomainNameTracks";
const VisibileHostingTracks =
  process.env.REACT_APP_visibileHostingTracks_PATH || "/visibileHostingTracks";
const VisibileServiceTracks =
  process.env.REACT_APP_visibileServiceTracks_PATH || "/visibileServiceTracks";

const getTracksAndLicenceByUniqueId =
  process.env.REACT_APP_getTracksAndLicenceByUniqueId_PATH ||
  "/getTracksAndLicenceByUniqueId";
//Dashboards
const dashboardPath = process.env.REACT_APP_Dashboard_PATH || "/dashboardAll";

//Templates
const templatesPath = process.env.REACT_APP_Templates_PATH || "/templates";
const addTemplatePath =
  process.env.REACT_APP_addTemplate_PATH || "/addTemplate";
const deleteTemplatePath =
  process.env.REACT_APP_deleteTemplate_PATH || "/deleteTemplate";

//Licene

const addLicence = process.env.REACT_APP_addLicence_PATH || "/licenceAdd";
const updateLicence =
  process.env.REACT_APP_updateLicence_PATH || "/licenceUpdate";
const deleteLicence =
  process.env.REACT_APP_deleteLicence_PATH || "/licenceDelete";
const allLicence = process.env.REACT_APP_allLicence_PATH || "/licence";
const visibileLicence =
  process.env.REACT_APP_visibileLicence_PATH || "/visibileLicence";

//Support
const addSupportPath = process.env.REACT_APP_addSupport_PATH || "/addSupport";
const getSupportPath = process.env.REACT_APP_getSupport_PATH || "/getSupport";
const transferSupportPath =
  process.env.REACT_APP_transferSupport_PATH || "/transferSupport";
const completedSupportPath =
  process.env.REACT_APP_completedSupport_PATH || "/completedSupport";

//Costs
const costsPath = process.env.REACT_APP_costs_PATH || "/costs";
const addCostPath = process.env.REACT_APP_addCosts_PATH || "/addCosts";
const DeleteCostPath = process.env.REACT_APP_deleteCosts_PATH || "/deleteCosts";
const EditCostPath = process.env.REACT_APP_editCosts_PATH || "/editCosts";
//Products
const ProductPath = process.env.REACT_APP_products_PATH || "/products";
const addProductPath = process.env.REACT_APP_addProduct_PATH || "/addProduct";
const deleteProductPath =
  process.env.REACT_APP_deleteProduct_PATH || "/deleteProduct";
const EditProductPath =
  process.env.REACT_APP_editProduct_PATH || "/editProduct";
const AddExcelProductPath =
  process.env.REACT_APP_addExcelProduct_PATH || "/addExcelProduct";
//Offers
const offersPath = process.env.REACT_APP_offers_PATH || "/offers";
const addOffersPath = process.env.REACT_APP_addOffers_PATH || "/addOffer";
const editOffersPath = process.env.REACT_APP_editOffers_PATH || "/editOffer";
const offerContentsPath =
  process.env.REACT_APP_offerContents_PATH || "/offerContents";
const getOrderPath = process.env.REACT_APP_getOrder_PATH || "/getOrder";
const ordersPath = process.env.REACT_APP_Orders_PATH || "/orders";

//Announces
const AnnouncesPath = process.env.REACT_APP_announces_PATH || "/announces";
const addAnnouncePath =
  process.env.REACT_APP_addAnnounce_PATH || "/addAnnounce";
const wasReadPath = process.env.REACT_APP_wasRead_PATH || "/wasRead";

//Note
const addNote = process.env.REACT_APP_addNote_PATH || "/addNote";
const privateNote = process.env.REACT_APP_privateNote_PATH || "/privateNote";
const updatedNote = process.env.REACT_APP_updateNote_PATH || "/updateNote";
const deleteNote = process.env.REACT_APP_deleteNote_PATH || "/deleteNote";

//Chat
const storeChat = process.env.REACT_APP_store_PATH || "/store";
const fetchChat =
  process.env.REACT_APP_fetchChatByUniqueId_PATH || "/fetchChatByUniqueId";

//Calendars
const addCalendar = process.env.REACT_APP_addCalendar_PATH || "/addCalendar";
const calendarPath = process.env.REACT_APP_Calendar_PATH || "/calendar";
const deleteCalendarPath =
  process.env.REACT_APP_deleteCalendar_PATH || "/deleteCalendar";
const editCalendarPath =
  process.env.REACT_APP_editCalendar_PATH || "/editCalendar";
//Sms

const smsPersonsPath = process.env.REACT_APP_smsPersons_PATH || "/smsUsersAll";
const smsCreditsPath = process.env.REACT_APP_smsCredits_PATH || "/smsCredits";
const smsPath = process.env.REACT_APP_sms_PATH || "/smsAll";
const sendSmsPath = process.env.REACT_APP_sendSms_PATH || "/sendSms";
const AddExcelSmsUserPath =
  process.env.REACT_APP_addExcelSmsUsers_PATH || "/addExcelSmsUsers";
const smsUserPath = process.env.REACT_APP_smsUsers_PATH || "/smsUsers";
const addSmsPerson = process.env.REACT_APP_addSmsPerson_PATH || "/addSmsPerson";
const deleteSmsPerson =
  process.env.REACT_APP_deleteSmsPerson_PATH || "/deleteSmsPerson";

//Panel/Customer
const customerPanelByUniqueId =
  process.env.REACT_APP_customerPanelByUniqueId_PATH ||
  "/customerPanelByUniqueId";

//Panel/Support
const addCustomerSupport =
  process.env.REACT_APP_addCustomerSupport_PATH || "/addCustomerSupport";

const supportsByUniqueId =
  process.env.REACT_APP_supportsByUniqueId_PATH || "/supportsByUniqueId";

//Panel/Information

const addMail = process.env.REACT_APP_addMail_PATH || " /addMail";
const getMail = process.env.REACT_APP_getMail_PATH || " /getMail";
const updateMail = process.env.REACT_APP_updateMail_PATH || "/updateMail";
const deleteMail = process.env.REACT_APP_deleteMail_PATH || "/deleteMail";

//Panel/Benefits
const customerPanelBenefits =
  process.env.REACT_APP_customerPanelBenefits_PATH || "/customerPanelBenefits ";

//Panel/Security
const addSecurity = process.env.REACT_APP_addSecurity_PATH || "/addSecurity";

const getSecurity = process.env.REACT_APP_getSecurity_PATH || "/getSecurity";
const getSecurityAll =
  process.env.REACT_APP_getSecurityAll_PATH || "/getSecurityAll";

const deleteSecurity =
  process.env.REACT_APP_deleteSecurity_PATH || "/deleteSecurity";

// Panel/AgeGraph
const addAgeGraph = process.env.REACT_APP_addAgeGraph_PATH || "/addAgeGraph";
const getAgeGraph = process.env.REACT_APP_getAgeGraph_PATH || "/getAgeGraph";
const deleteAgeGraph =
  process.env.REACT_APP_deleteAgeGraph_PATH || "/deleteAgeGraph";
const getAgeGraphAll =
  process.env.REACT_APP_getAgeGraphAll_PATH || "/getAgeGraphAll";

//Panel/TrafficSource
const addTrafficSource =
  process.env.REACT_APP_addTrafficSource_PATH || "/addTrafficSource";
const getTrafficSource =
  process.env.REACT_APP_getTrafficSource_PATH || "/getTrafficSource";
const getTrafficSourceAll =
  process.env.REACT_APP_getTrafficSourceAll_PATH || "/getTrafficSourceAll";
const deleteTrafficSource =
  process.env.REACT_APP_deleteTrafficSource_PATH || "/deleteTrafficSource";

//Panel/DevicesGraph

const addDevices = process.env.REACT_APP_addDevices_PATH || "/addDevices";

const getDevices = process.env.REACT_APP_getDevices_PATH || "/getDevices";

const getDevicesAll =
  process.env.REACT_APP_getDevicesAll_PATH || "/getDevicesAll";
const GetWebsiteAllPath =
  process.env.REACT_APP_getWebsiteAll_PATH || "/getWebsiteAll";
const deleteDevices =
  process.env.REACT_APP_deleteDevices_PATH || "/deleteDevices";

//Panel/City

const addCity = process.env.REACT_APP_addCity_PATH || "/addCity";
const getCity = process.env.REACT_APP_getCity_PATH || "/getCity";
const deleteCity = process.env.REACT_APP_deleteCity_PATH || "/deleteCity";

const updateCity = process.env.REACT_APP_updateCity_PATH || "/updateCity";

//Panel/CountryStatistics

const addCountryStatistics =
  process.env.REACT_APP_addCountryStatistics_PATH || "/addCountryStatistics";

const getCountryStatistics =
  process.env.REACT_APP_getCountryStatistics_PATH || "/getCountryStatistics";

const getCountryStatisticsAll =
  process.env.REACT_APP_getCountryStatisticsAll_PATH ||
  "/getCountryStatisticsAll";
const deleteCountryStatistics =
  process.env.REACT_APP_deleteCountryStatistics_PATH ||
  "/deleteCountryStatistics";

const updateCountryStatistics =
  process.env.REACT_APP_updateCountryStatistics_PATH ||
  "/updateCountryStatistics";

//Panel/BestKeywords

const addBestKeywords =
  process.env.REACT_APP_addBestKeywords_PATH || "/addBestKeywords";

const getBestKeywords =
  process.env.REACT_APP_getBestKeywords_PATH || "/getBestKeywords";

const deleteBestKeywords =
  process.env.REACT_APP_deleteBestKeywords_PATH || " /deleteBestKeywords";
const updateBestKeywords =
  process.env.REACT_APP_updateBestKeywords_PATH || "/updateBestKeywords";

const getBestKeywordsAll =
  process.env.REACT_APP_getBestKeywordsAll_PATH || "/getBestKeywordsAll";

//Panel/SocialMedia

const addSocialMediaPath =
  process.env.REACT_APP_addSocialMedia_PATH || "/addSocialMedia";
const getSocialMedia =
  process.env.REACT_APP_getSocialMedia_PATH || "/getSocialMedia";
const getSocialMediaView =
  process.env.REACT_APP_getSocialMediaView_PATH || "/getSocialMediaView";
const deleteSocialMedia =
  process.env.REACT_APP_deleteSocialMedia_PATH || "/deleteSocialMedia";

const getCurrentData =
  process.env.REACT_APP_getCurrentData_PATH || "/getCurrentData";
//Panel/SocialMedia

const addMaps = process.env.REACT_APP_addMaps_PATH || "/addMaps";
const getMaps = process.env.REACT_APP_getMaps_PATH || "/getMaps";
const GetMapsView = process.env.REACT_APP_GetMapsView_PATH || "/getMapsView";
const deleteMap = process.env.REACT_APP_deleteMap_PATH || "/deleteMap";

//Panel/ViewVisitor
const addViewVisitor =
  process.env.REACT_APP_addViewVisitor_PATH || "/addViewVisitor";
const getViewVisitor =
  process.env.REACT_APP_getViewVisitor_PATH || "/getViewVisitor";
const deleteViewVisitor =
  process.env.REACT_APP_deleteViewVisitor_PATH || " /deleteViewVisitor";

//Panel/GoogleAds
const addAds = process.env.REACT_APP_addAds_PATH || "/addAds";
const getAds = process.env.REACT_APP_getAds_PATH || "/getAds";
const deleteAds = process.env.REACT_APP_deleteAds_PATH || "/deleteAds";
const GetAdsAllPath = process.env.REACT_APP_getAdsAll_PATH || "/getAdsAll";

//Panel/SourceAds
const addSourceAds = process.env.REACT_APP_addSourceAds_PATH || "/addSourceAds";
const getSourceAds = process.env.REACT_APP_getSourceAds_PATH || "/getSourceAds";
const getSourceAdsAll =
  process.env.REACT_APP_getSourceAdsAll_PATH || "/getSourceAdsAll";
const deleteSourceAds =
  process.env.REACT_APP_deleteSourceAds_PATH || "/deleteSourceAds";

//Panel/CostAds
const addCostAds = process.env.REACT_APP_addCostAds_PATH || "/addCostAds";
const getCostAds = process.env.REACT_APP_getCostAds_PATH || "/getCostAds";
const getCostAdsAll = process.env.REACT_APP_getCostAds_PATH || "/getCostAdsAll";
const deleteCostAds =
  process.env.REACT_APP_deleteCostAds_PATH || "/deleteCostAds";

//Marketting Chase
const addMarkettingChase =
  process.env.REACT_APP_addMarkettingChase_PATH || "/addMarkettingChase";
const getMarkettingChase =
  process.env.REACT_APP_getMarkettingChase_PATH || "/getMarkettingChase";
const updateMarkettingChase =
  process.env.REACT_APP_updateMarkettingChase_PATH || "/updateMarkettingChase";
const deleteMarkettingChase =
  process.env.REACT_APP_deleteMarkettingChase_PATH || "/deleteMarkettingChase";

//Panel/RivalAds
const addRivalAds = process.env.REACT_APP_addRivalAds_PATH || "/addRivalAds";
const getRivalAds = process.env.REACT_APP_getRivalAds_PATH || "/getRivalAds";
const getRivalAdsAll =
  process.env.REACT_APP_getRivalAdsAll_PATH || "/getRivalAdsAll";
const deleteRivalAds =
  process.env.REACT_APP_deleteRivalAds_PATH || "/deleteRivalAds";

//Panel/CostAds
const addDeviceAds = process.env.REACT_APP_addDeviceAds_PATH || "/addDeviceAds";
const getDeviceAds = process.env.REACT_APP_getDeviceAds_PATH || "/getDeviceAds";
const getDeviceAdsAll =
  process.env.REACT_APP_getDeviceAdsAll_PATH || "/getDeviceAdsAll";
const deleteDeviceAds =
  process.env.REACT_APP_deleteDeviceAds_PATH || "/deleteDeviceAds";

//Files
const addFileType = process.env.REACT_APP_addFileType_PATH || "/addFileType";
const filesPath = process.env.REACT_APP_Files_PATH || "/files";
const addFilePath = process.env.REACT_APP_addFile_PATH || "/addFile";
const deleteFilePath = process.env.REACT_APP_deleteFile_PATH || "/deleteFile";

//Emails
const mailPersonPath = process.env.REACT_APP_mailPerson_PATH || "/mailPersons";
const deleteMailPerson =
  process.env.REACT_APP_deleteSmsPerson_PATH || "/deleteMailPerson";
const sendMailPath = process.env.REACT_APP_sendMail_PATH || "/sendMail";
const mailsPath = process.env.REACT_APP_mails_PATH || "/mails";
const addMailPersonPath =
  process.env.REACT_APP_addMailPerson_PATH || "/addMailPerson";
//Panel/OtherAccounts

const addOtherAccounts =
  process.env.REACT_APP_addOtherAccounts_PATH || "/addOtherAccounts";

const getOtherAccounts =
  process.env.REACT_APP_getOtherAccounts_PATH || "/getOtherAccounts";
const updateOtherAccounts =
  process.env.REACT_APP_updateOtherAccounts_PATH || "/updateOtherAccounts";

const deleteOtherAccounts =
  process.env.REACT_APP_deleteOtherAccounts_PATH || "/deleteOtherAccounts";

//Panel/Domain
const getDomain = process.env.REACT_APP_getDomain_PATH || "/getDomain ";

//Panel/NoteEditor
const addNoteEditor =
  process.env.REACT_APP_addNoteEditor_PATH || "/addNoteEditor";
const getNoteEditor =
  process.env.REACT_APP_getNoteEditor_PATH || "/getNoteEditor";
//Order Payment
const orderPaymentPath =
  process.env.REACT_APP_orderPayment_PATH || "/orderPayment";
const payOrdersPath = process.env.REACT_APP_payOrders_PATH || "/payOrders";
//PanelLanguages
const addLanguages = process.env.REACT_APP_addLanguages_PATH || "/addLanguages";
const getLanguages = process.env.REACT_APP_getLanguages_PATH || "/getLanguages";
const deleteLanguages =
  process.env.REACT_APP_deleteLanguages_PATH || "/deleteLanguages";

//PanelPages
const addPagesGraph =
  process.env.REACT_APP_addPagesGraph_PATH || "/addPagesGraph";
const getPagesGraph =
  process.env.REACT_APP_getPagesGraph_PATH || "/getPagesGraph";
const deletePagesGraph =
  process.env.REACT_APP_deletePagesGraph_PATH || "/deletePagesGraph";

//Cutomer/Quatation
const addCustomerForm =
  process.env.REACT_APP_addCustomerForm_PATH || "/addCustomerForm";

const allCustomerForm =
  process.env.REACT_APP_allCustomerForm_PATH || "/allCustomerForm";

const deleteCustomerForm =
  process.env.REACT_APP_deleteCustomerForm_PATH || "/deleteCustomerForm";

const transferCustomerForm =
  process.env.REACT_APP_transferCustomerForm_PATH || "/transferCustomerForm";

//Brand
const addBrand = process.env.REACT_APP_addBrand_PATH || "/addBrand";
const updateBrand = process.env.REACT_APP_updateBrand_PATH || "/updateBrand";
const deleteBrand = process.env.REACT_APP_deleteBrand_PATH || "/deleteBrand";
const addTimeBrand = process.env.REACT_APP_addTimeBrand_PATH || "/addTimeBrand";

//Supplier
const addSupplier = process.env.REACT_APP_addSupplier_PATH || "/addSupplier";
const deleteSupplier =
  process.env.REACT_APP_deleteSupplier_PATH || "/deleteSupplier";
const updateSupplier =
  process.env.REACT_APP_updateSupplier_PATH || "/updateSupplier";
const allSupplier = process.env.REACT_APP_allSupplier_PATH || "/allSupplier";

//Services
const addServices = process.env.REACT_APP_addServices_PATH || "/addServices";

const allServices = process.env.REACT_APP_allServices_PATH || "/allServices";

const updateServices =
  process.env.REACT_APP_updateServices_PATH || "/updateServices";
const deleteServices =
  process.env.REACT_APP_deleteServices_PATH || "/deleteServices";

//Guarantee
const allGuarantee = process.env.REACT_APP_allGuarantee_PATH || "/allGuarantee";

const addGuarantee = process.env.REACT_APP_addGuarantee_PATH || "/addGuarantee";

const updateGuarantee =
  process.env.REACT_APP_updateGuarantee_PATH || "/updateGuarantee";

const deleteGuarantee =
  process.env.REACT_APP_deleteGuarantee_PATH || "/deleteGuarantee";

const visibileGuarantee =
  process.env.REACT_APP_visibileGuarantee_PATH || "/visibileGuarantee";

const addTimeGuarantee =
  process.env.REACT_APP_addTime_PATH || "/addTimeGuarantee";
export const MailPersonService = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${mailPersonPath}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const DeleteMailPersonService = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteMailPerson}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({ id }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const MailService = async () => {
  try {
    const response = await axios.get(`${baseEndpoint}${apiPath}${mailsPath}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const SendMailService = async (
  to_who,
  by_who,
  subject,
  content,
  files
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${sendMailPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        to_who,
        by_who,
        subject,
        content,
        files,
      }),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const AddMailPersonService = async (
  name,
  surname,
  desc,
  title,
  mail
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${addMailPersonPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          name,
          surname,
          desc,
          title,
          mail,
        }),
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

//Order Payments
export const OrderPaymentService = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${orderPaymentPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const PayOrdersService = async (orders, customer_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${payOrdersPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        orders,
        customer_id,
      }),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
//Files
export const FileService = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${filesPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const AddFileService = async (user_id, file, type) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addFilePath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id,
        file,
        type,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Başarısız:", error.message);
    return false;
  }
};
export const AddFileTypeService = async (name) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addFileType}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        name,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Başarısız:", error.message);
    return false;
  }
};
export const DeleteFileService = async (user_id, file_id, type_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteFilePath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id: Number(user_id),
        file_id,
        type_id,
      }),
    });

    const data = await response.json();
    //window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Marketting Chase
export const MarkettingChaseService = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${getMarkettingChase}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const AddMarkettingChaseService = async (
  customer,
  employee,
  source,
  status,
  service,
  description,
  date
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${addMarkettingChase}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          customer,
          employee,
          source,
          status,
          service,
          description,
          date,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const UpdateMarkettingChaseService = async (
  id,
  customer,
  employee,
  source,
  status,
  service,
  description,
  date,
  meet,
  notification,
  by_who
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${updateMarkettingChase}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          customer,
          employee,
          source,
          status,
          service,
          description,
          date,
          meet,
          notification,
          by_who,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteMarkettingChaseService = async (chase_id) => {
  let id = chase_id;
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteMarkettingChase}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Jobs
export const JobsService = async () => {
  try {
    const response = await axios.get(`${baseEndpoint}${apiPath}${jobsPath}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const AddJobsService = async (
  cus,
  unique_id,
  subject,
  desc,
  dep,
  emp,
  date,
  status,
  userId
) => {
  try {
    const formattedEmp = emp ? emp.join(",") : "";

    const response = await fetch(`${baseEndpoint}${apiPath}${addJobsPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        cus,
        unique_id,
        subject,
        desc,
        dep,
        emp: formattedEmp,
        date,
        status,
        userId,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const EditJobService = async (requestBody) => {
  try {
    let id = requestBody.id;
    let customer = requestBody.newCus;
    let subject = requestBody.newSub;
    let state = requestBody.newState;
    let description = requestBody.newDesc;
    let department = requestBody.newDep;
    let date = requestBody.newDate;
    let assigned_emp = requestBody.assignedEmp;
    const response = await fetch(`${baseEndpoint}${apiPath}${EditJobPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        customer,
        subject,
        state,
        description,
        department,
        date,
      }),
    });
    AssignJobService(assigned_emp, id);
    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const AssignJobService = async (user_id, job_id, userId) => {
  try {
    const user_id_string = Array.isArray(user_id) ? user_id.join(",") : user_id;
    const response = await fetch(`${baseEndpoint}${apiPath}${assignJobsPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id: user_id_string,
        job_id,
        userId,
      }),
    });

    const data = await response.json();
    //window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const FinishJobService = async (id, finisher) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${finishJobsPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        finisher,
      }),
    });

    const data = await response.json();
    window.location.href = "/jobs";
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const JobDetailService = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${JobDetailsPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const AddStageService = async (requestBody) => {
  try {
    let jobs_no = requestBody.id;
    let stage = requestBody.stage;
    let user_id = requestBody.user_id;

    const response = await fetch(`${baseEndpoint}${apiPath}${AddStagePath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        jobs_no,
        stage,
        user_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteJobService = async (job_id) => {
  let id = job_id;
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${DeleteJobPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteStageService = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${DeleteStagePath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const EditStageService = async (id, stage) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${EditStagePath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        stage,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
//Chat

export const StoreChat = async (incoming_msg_id, outgoing_msg_id, message) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${storeChat}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        incoming_msg_id,
        outgoing_msg_id,
        message,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const FetchChat = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${fetchChat}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

// Manufacturing

export const ManufacturingService = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${ManufacturingPath}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const addManufacturing = async (
  date,
  customer,
  product,
  quantity,
  unit,
  features,
  supplier,
  cost_amount,
  sales_amount,
  bill,
  status,
  payment,
  constituent
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${AddManufacturing}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          date,
          customer,
          product,
          quantity,
          unit,
          features,
          supplier,
          cost_amount,
          sales_amount,
          bill,
          status,
          payment,
          constituent,
        }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const updateManufacturing = async (
  id,
  date,
  customer,
  product,
  quantity,
  unit,
  features,
  supplier,
  cost_amount,
  sales_amount,
  bill,
  status,
  payment
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${UpdateManufacturing}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          date,
          customer,
          product,
          quantity,
          unit,
          features,
          supplier,
          cost_amount,
          sales_amount,
          bill,
          status,
          payment,
        }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const deleteManufacturing = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${DeleteManufacturing}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );
    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
//Users
export const AddUserService = async (requestBody) => {
  try {
    let name = requestBody.name;
    let email = requestBody.email;
    let phone = requestBody.phone;
    let password = requestBody.password;
    let site = requestBody.site;
    let department = requestBody.department;
    let type = requestBody.type;
    let link = requestBody.link;
    const response = await fetch(`${baseEndpoint}${apiPath}${PostUserPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        name,
        email,
        phone,
        password,
        site,
        department,
        type,
        link,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const AddExcelEmployeeService = async (ex_data) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${AddExcelEmployeePath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          ex_data,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const EditUserService = async (requestBody) => {
  try {
    let name = requestBody.newName;
    let email = requestBody.newEmail;
    let image = requestBody.newImage;
    let phone = requestBody.newPhone;
    let password = requestBody.newPassword;
    let department = requestBody.newDep;
    let id = requestBody.id;
    let type = requestBody.newType;
    let link = requestBody.newLink;
    let is_auth = requestBody.is_auth;

    const response = await fetch(`${baseEndpoint}${apiPath}${EditUserPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        name,
        email,
        image,
        phone,
        password,
        department,
        type,
        is_auth,
        link,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetUserByUniqueId = async (unique_id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getUserByUniqueId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteUserService = async (user_id) => {
  let id = user_id;
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${DeleteUserPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const UserService = async () => {
  try {
    const response = await axios.get(`${baseEndpoint}${apiPath}${UserPath}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

//Permissions
export const CancelPermissionsRequest = async (id, status, name) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${cancelPermission}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          status,
          name,
        }),
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const AddPermissions = async (
  name,
  unique_id,
  type,
  note,
  starting_date,
  renewal_date,
  document
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addPermissions}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        name,
        unique_id,
        type,
        starting_date,
        renewal_date,
        note,
        document,
      }),
    });
    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const UpdatePermissionStatus = async (id, status, name) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${updatePermissionStatus}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          status,
          name,
        }),
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const AllPermissions = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${allPermissions}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

//Licence
export const LicenceService = async () => {
  try {
    const response = await axios.get(`${baseEndpoint}${apiPath}${allLicence}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const VisibileLicence = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${visibileLicence}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const AddLicence = async (
  packages,
  domain_name,
  starting_date,
  renewal_date
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addLicence}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        packages,
        domain_name,
        starting_date,
        renewal_date,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const UpdateLicence = async (
  id,
  packages,
  domain_name,
  starting_date,
  renewal_date
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${updateLicence}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        packages,
        domain_name,
        starting_date,
        renewal_date,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteLicence = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteLicence}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Note
export const AddNote = async (user_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addNote}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id,
      }),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const PrivateNote = async (user_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${privateNote}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const UpdateNote = async (id, title, description) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${updatedNote}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        title,
        description,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteNote = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteNote}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
//Tasks
export const TaskService = async () => {
  try {
    const response = await axios.get(`${baseEndpoint}${apiPath}${tasksPath}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const FinishTask = async (id, finisher) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${finishTasksPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          finisher,
        }),
      }
    );

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const EditTaskService = async (
  id,
  newName,
  newDesc,
  newDep,
  taskStatus
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${updateTasksPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          newName,
          newDesc,
          newDep,
          taskStatus,
        }),
      }
    );

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const AssignTaskService = async (user_id, task_id, userId) => {
  try {
    const user_id_string = Array.isArray(user_id) ? user_id.join(",") : user_id;
    const response = await fetch(`${baseEndpoint}${apiPath}${assignTaskPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id: user_id_string,
        task_id,
        userId,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const AddTasksService = async (
  name,
  unique_id,
  desc,
  department,
  status
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addTasksPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        name,
        unique_id,
        desc,
        department,
        status,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteTaskService = async (task_id) => {
  let id = task_id;
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${DeleteTaskPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
//Support
export const SupportService = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${getSupportPath}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const AddSupportService = async (
  name,
  unique_id,
  desc,
  department,
  status
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addSupportPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        name,
        unique_id,
        desc,
        department,
        status,
      }),
    });
    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const TransferSupportService = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${transferSupportPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const CompletedSupportService = async (id, name) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${completedSupportPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          name,
        }),
      }
    );

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
//Tracks
export const TrackService = async () => {
  try {
    const response = await axios.get(`${baseEndpoint}${apiPath}${Tracks}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const AddTrack = async (
  service_type,
  domain_name,
  platform,
  customer,
  owner,
  starting_date,
  renewal_date
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${AddTracks}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        service_type,
        domain_name,
        platform,
        customer,
        owner,
        starting_date,
        renewal_date,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const AddTimeService = async (id, time) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${AddTime}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        time,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const UpdateTrack = async (
  service_type,
  domain_name,
  platform,
  customer,
  owner,
  starting_date,
  renewal_date
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${UpdateTracks}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        service_type,
        domain_name,
        platform,
        customer,
        owner,
        starting_date,
        renewal_date,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const UpdateServiceTrack = async (
  service_type,
  domain_name,
  platform,
  customer,
  owner,
  starting_date,
  renewal_date
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${UpdateServiceTracks}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          service_type,
          domain_name,
          platform,
          customer,
          owner,
          starting_date,
          renewal_date,
        }),
      }
    );

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteTrack = async (domain_name) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${DeleteTracks}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        domain_name,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const deleteHostingTracks = async (id, domain_name) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${DeleteHostingTracks}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          domain_name,
        }),
      }
    );

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const deleteServiceTracks = async (id, domain_name) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${DeleteServiceTracks}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          domain_name,
        }),
      }
    );

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const modernizeTrack = async (
  id,
  domain_name,
  platform,
  customer,
  owner,
  starting_date,
  renewal_date
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${ModernizeTrack}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        domain_name,
        platform,
        customer,
        owner,
        starting_date,
        renewal_date,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const visibileDomainNameTracks = async (domain_name) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${VisibileDomainNameTracks}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          domain_name,
        }),
      }
    );

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const visibileHostingTracks = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${VisibileHostingTracks}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const visibileServiceTracks = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${VisibileServiceTracks}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
//Customers
export const CustomerService = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${CustomerPath}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const DeleteCustomerService = async (requestBody) => {
  let id = requestBody;
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${DeleteCustomerPath}`,

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();
    //window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const AddCustomerService = async (requestBody) => {
  try {
    let name = requestBody.name;
    let title = requestBody.title;
    let phone = requestBody.phone;
    let address = requestBody.address;
    let site = requestBody.site;
    let taxAdmin = requestBody.taxAdmin;
    let taxNumber = requestBody.taxNumber;
    let type = requestBody.type;
    let email = requestBody.mail;
    let image = requestBody.image;
    let key_words = requestBody.keyWords;
    const response = await fetch(
      `${baseEndpoint}${apiPath}${PostCustomerPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          name,
          title,
          phone,
          address,
          site,
          taxAdmin,
          taxNumber,
          type,
          email,
          image,
          key_words,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const AddExcelCustomerService = async (ex_data) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${AddExcelCustomerPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          ex_data,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const EditCustomerService = async (requestBody) => {
  try {
    let id = requestBody.id;
    let name = requestBody.name;
    let title = requestBody.title;
    let phone = requestBody.phone;
    let address = requestBody.address;
    let site = requestBody.site;
    let taxAdmin = requestBody.taxAdmin;
    let taxNumber = requestBody.taxNumber;
    let type = requestBody.type;
    let email = requestBody.email;
    let image = requestBody.image;
    let key_words = requestBody.keyWords;
    const response = await fetch(
      `${baseEndpoint}${apiPath}${EditCustomerPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          name,
          title,
          email,
          phone,
          address,
          taxAdmin,
          taxNumber,
          site,
          type,
          image,
          key_words,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
//Entegrations
export const EntegrationService = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${EntegrationPath}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const PaymentSettingsService = async (
  merchant_id,
  merchant_key,
  merchant_salt
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${savePaymentSettings}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          merchant_id,
          merchant_key,
          merchant_salt,
        }),
      }
    );
    window.location.reload();
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const SmsSettingsService = async (
  sms_user_code,
  sms_password,
  sms_header,
  sms_language,
  sms_app_key
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${saveSmsSettings}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          sms_user_code,
          sms_password,
          sms_header,
          sms_language,
          sms_app_key,
        }),
      }
    );
    window.location.reload();
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Departments
export const DepartmentService = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${DepartmentPath}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const deleteDepartment = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${DeleteDepartmentPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const updateDepartment = async (id, department) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${UpdateDepartment}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          department,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const AddDeparment = async (department) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${AddDepartment}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        department,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Todo List
export const TodoListService = async (user_id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getTodoListPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          user_id,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteTodoService = async (id, state) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${DeleteTodoPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        state,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const AddTodoService = async (
  user_id,
  day,
  title,
  time_hour,
  status
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addTodoPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id,
        day,
        title,
        time_hour,
        status,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const SaveTodoService = async (
  id,
  user_id,
  day,
  title,
  time_hour,
  status
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${editTodoPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({ id, user_id, day, title, time_hour, status }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const ChangeDayTodoService = async (id, day) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${editTodoPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({ id, day }),
    });
    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Dashboard
export const DashboardService = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${dashboardPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Costs
export const CostService = async () => {
  try {
    const response = await axios.get(`${baseEndpoint}${apiPath}${costsPath}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const AddCostService = async (requestBody) => {
  try {
    let title = requestBody.name;
    let desc = requestBody.desc;
    let id = requestBody.user_id;
    let file = requestBody.file;
    let department = requestBody.department;

    const response = await fetch(`${baseEndpoint}${apiPath}${addCostPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        title,
        desc,
        file,

        department,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const EditCostService = async (requestBody) => {
  try {
    let id = requestBody.id;
    let title = requestBody.name;
    let desc = requestBody.desc;
    let user_id = requestBody.user_id;
    let file = requestBody.file;
    let department = requestBody.department;
    const response = await fetch(`${baseEndpoint}${apiPath}${EditCostPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        user_id,
        title,
        desc,
        file,
        department,
      }),
    });

    const data = await response.json();
    // window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Products
export const ProductService = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${ProductPath}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const AddExcelProductService = async (ex_data) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${AddExcelProductPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          ex_data,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const AddProductService = async (requestBody) => {
  try {
    let name = requestBody.name;
    let quantity = requestBody.quantity;
    let tax_percentage = requestBody.taxPercent;
    let brand = requestBody.brand;
    let currency = requestBody.currency;
    let purchase_price = requestBody.purchasePrice;
    let sale_price = requestBody.salePrice;
    let unit = requestBody.unit;
    let image = requestBody.image;
    const response = await fetch(`${baseEndpoint}${apiPath}${addProductPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        name,
        quantity,
        tax_percentage,
        brand,
        currency,
        purchase_price,
        sale_price,
        unit,
        image,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteProductService = async (product_id) => {
  let id = product_id;
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteProductPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();
    //window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const EditProductService = async (requestBody) => {
  try {
    let id = requestBody.id;
    let name = requestBody.name;
    let quantity = requestBody.quantity;
    let tax_percentage = requestBody.taxPercent;
    let brand = requestBody.brand;
    let currency = requestBody.currency;
    let purchase_price = requestBody.purchasePrice;
    let sale_price = requestBody.salePrice;
    let image = requestBody.image;
    const response = await fetch(
      `${baseEndpoint}${apiPath}${EditProductPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          name,
          quantity,
          tax_percentage,
          brand,
          currency,
          purchase_price,
          sale_price,
          image,
        }),
      }
    );

    const data = await response.json();
    // window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteCostService = async (cost_id) => {
  let id = cost_id;
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${DeleteCostPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
//Templates
export const AddTemplatesService = async (name, description, type) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${addTemplatePath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          name,
          description,
          type,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteTemplateService = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteTemplatePath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const TemplatesService = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${templatesPath}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

//Offers
export const OfferService = async () => {
  try {
    const response = await axios.get(`${baseEndpoint}${apiPath}${offersPath}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
export const AddOfferService = async (requestBody) => {
  try {
    let cust = requestBody.cust;
    let statusTaxKdv = requestBody.statusTaxKdv;
    let subject = requestBody.subject;
    let temp = requestBody.temp;
    let note = requestBody.note;
    let desc = requestBody.desc;
    let offer_content = requestBody.selectedProd;
    let user_id = requestBody.user_id;
    const response = await fetch(`${baseEndpoint}${apiPath}${addOffersPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        cust,
        statusTaxKdv,
        subject,
        temp,
        note,
        desc,
        offer_content,
        user_id,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const EditOfferService = async (requestBody) => {
  try {
    let id = requestBody.id;
    let cust = requestBody.cust;
    let statusTaxKdv = requestBody.statusTaxKdv;
    let subject = requestBody.subject;
    let temp = requestBody.temp;
    let note = requestBody.noteEdit;
    let description = requestBody.noteTwoEdit;
    let offer_content = requestBody.selectedProd;
    let user_id = requestBody.user_id;

    const response = await fetch(`${baseEndpoint}${apiPath}${editOffersPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        customer_id: cust,
        tax_status: statusTaxKdv,
        subject,
        template_id: temp,
        note,
        description,
        offer_content,
        user_id,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const OfferContentService = async (requestBody) => {
  try {
    let id = requestBody.id;

    const response = await fetch(
      `${baseEndpoint}${apiPath}${offerContentsPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetOrderService = async (id, prods) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getOrderPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        prods,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const OrderService = async () => {
  try {
    const response = await axios.get(`${baseEndpoint}${apiPath}${ordersPath}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
//Announces
export const AnnounceService = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${AnnouncesPath}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const AddAnnounceService = async (requestBody) => {
  try {
    let user_id = requestBody.user_id;
    let subject = requestBody.subject;
    let desc = requestBody.email;
    let checked = requestBody.checked;
    let dep = requestBody.dep;
    let assigned_emp = requestBody.assignedEmp;

    if (checked) {
      checked = 1;
    }

    const response = await fetch(
      `${baseEndpoint}${apiPath}${addAnnouncePath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          user_id,
          subject,
          desc,
          checked,
          dep,
          assigned_emp,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const WasReadService = async (user_id, announce_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${wasReadPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id,
        announce_id,
      }),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Calendars
export const AddCalendarService = async (requestBody) => {
  try {
    let user_id = requestBody.user_id;
    let subject = requestBody.subject;
    let desc = requestBody.desc;
    let start_date = requestBody.start_date;
    let end_date = requestBody.end_date;
    let time = requestBody.time;
    let time_two = requestBody.timeTwo;
    const response = await fetch(`${baseEndpoint}${apiPath}${addCalendar}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id,
        subject,
        desc,
        start_date,
        end_date,
        time,
        time_two,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const EditCalendarService = async (requestBody) => {
  try {
    let cal_id = requestBody.cal_id;
    let desc = requestBody.desc;
    let subject = requestBody.subject;
    let time_start = requestBody.time;
    let time_end = requestBody.timeTwo;
    let start = requestBody.start;
    let end = requestBody.end;
    const response = await fetch(
      `${baseEndpoint}${apiPath}${editCalendarPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          cal_id,
          subject,
          desc,
          time_start,
          time_end,
          start,
          end,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const CalendarService = async (user_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${calendarPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        user_id,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteCalendarService = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteCalendarPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

// Panel/Customer
export const CustomerPanelByUniqueId = async (unique_id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${customerPanelByUniqueId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
//Panel/Map
export const AddMaps = async (
  unique_id,
  type,
  web,
  month,
  road,
  phone_search
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addMaps}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        type,
        web,
        month,
        road,
        phone_search,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
//Panel/Information

export const AddMail = async (unique_id, name, mail, password, size) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addMail}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        name,
        mail,
        password,
        size,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetMaps = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getMaps}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const MapsView = async (unique_id, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${GetMapsView}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        month,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteMap = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteMap}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/SocialMedia
export const AddSocialMedia = async (
  unique_id,
  type,
  new_follower,
  month,
  total_follower,
  access,
  interactions,
  profile_visit,
  click
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${addSocialMediaPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
          type,
          new_follower,
          month,
          total_follower,
          access,
          interactions,
          profile_visit,
          click,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetSocialMedia = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getSocialMedia}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const SocialMediaView = async (unique_id, month) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getSocialMediaView}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
          month,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteSocialMedia = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteSocialMedia}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const GetCurrentData = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${getCurrentData}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

//Panel/Web/Security

export const AddSecurity = async (
  unique_id,
  country,
  blocked_ıp_address,
  blocked_number,
  month
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addSecurity}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        country,
        blocked_ıp_address,
        blocked_number,
        month,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetSecurity = async (unique_id, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getSecurity}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        month,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetSecurityAll = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getSecurityAll}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteSecurity = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteSecurity}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/Information

export const GetMail = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getMail}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const UpdateMail = async (editedData) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${updateMail}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        editedData,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteMail = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteMail}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/ViewVisitor

export const AddViewVisitor = async (unique_id, label, value, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addViewVisitor}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        label,
        value,
        month,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const GetViewVisitor = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getViewVisitor}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteViewVisitor = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteViewVisitor}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/DeviceAds

export const AddDeviceAds = async (
  unique_id,
  type,
  clicks,
  cost,
  month,
  show
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addDeviceAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        type,
        clicks,
        cost,
        month,
        show,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetDeviceAds = async (unique_id, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getDeviceAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        month,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetDeviceAdsAll = async (unique_id, month) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getDeviceAdsAll}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
          month,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteDeviceAds = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteDeviceAds}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/RivalAds

export const AddRivalAds = async (
  unique_id,

  url,
  show,
  month
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addRivalAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        url,
        show,
        month,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetRivalAds = async (unique_id, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getRivalAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        month,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetRivalAdsAll = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getRivalAdsAll}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteRivalAds = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteRivalAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/CostAds

export const AddCostAds = async (unique_id, key_words, clicks, cost, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addCostAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        key_words,
        clicks,
        cost,
        month,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetCostAds = async (unique_id, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getCostAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        month,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetCostAdsAll = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getCostAdsAll}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteCostAds = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteCostAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/SourceAds

export const AddSourceAds = async (unique_id, type, clicks, cost, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addSourceAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        type,
        clicks,
        cost,
        month,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetSourceAds = async (unique_id, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getSourceAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        month,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetSourceAdsAll = async (unique_id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getSourceAdsAll}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteSourceAds = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteSourceAds}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/GoogleAds

export const AddAds = async (unique_id, clicks, show, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        clicks,
        show,
        month,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetAds = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteAds = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteAds}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/BestKeywords

export const AddBestKeywords = async (
  unique_id,
  keywords,
  clicks,
  screening,
  month,
  platform
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${addBestKeywords}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
          keywords,
          clicks,
          screening,
          month,
          platform,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const GetBestKeywords = async (unique_id, month) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getBestKeywords}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
          month,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetBestKeywordsAll = async (unique_id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getBestKeywordsAll}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteBestKeywords = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteBestKeywords}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const UpdateBestKeywords = async (editedKeywords) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${updateBestKeywords}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          editedKeywords,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/City

export const AddCity = async (unique_id, city, visitor, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addCity}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        city,
        visitor,
        month,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const GetCity = async (unique_id, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getCity}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        month,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteCity = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteCity}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const UpdateCountryStatistics = async (editedCountry) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${updateCountryStatistics}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          editedCountry,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
//Panel/CountryStatistics

export const AddCountryStatistics = async (
  unique_id,
  country,
  visitor,
  month
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${addCountryStatistics}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
          country,
          visitor,
          month,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const GetCountryStatistics = async (unique_id, month) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getCountryStatistics}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
          month,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetCountryStatisticsAll = async (unique_id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getCountryStatisticsAll}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteCountryStatistics = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteCountryStatistics}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const UpdateCity = async (editedCity) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${updateCity}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        editedCity,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/AgeGraph

export const AddAgeGraph = async (unique_id, age, value, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addAgeGraph}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        age,
        value,
        month,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const GetAgeGraph = async (unique_id, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getAgeGraph}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        month,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetAgeGraphAll = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getAgeGraphAll}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteAgeGraph = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteAgeGraph}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/TrafficSource
export const AddTrafficSource = async (
  unique_id,
  traffic_source,
  value,
  month
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${addTrafficSource}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
          traffic_source,
          value,
          month,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const GetTrafficSource = async (unique_id, month) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getTrafficSource}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
          month,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetTrafficSourceAll = async (unique_id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getTrafficSourceAll}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteTrafficSource = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteTrafficSource}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
//Panel/Devices

export const AddDevices = async (unique_id, devices, value, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addDevices}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        devices,
        value,
        month,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const GetDevices = async (unique_id, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getDevices}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        month,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetDevicesAll = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getDevicesAll}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetWebsiteAll = async (unique_id, month) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${GetWebsiteAllPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
          month,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const GetAdsAll = async (unique_id, month) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${GetAdsAllPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        month,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteDevices = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteDevices}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/Benefits

export const CustomerPanelBenefits = async (unique_id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${customerPanelBenefits}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/Support

export const AddCustomerSupport = async (
  name,
  desc,
  department,
  status,
  unique_id
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${addCustomerSupport}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          name,
          desc,
          department,
          status,
          unique_id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const SupportsByUniqueId = async (unique_id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${supportsByUniqueId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
// Sms
export const SmsService = async () => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${smsPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({}),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteSmsPersonService = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteSmsPerson}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({ id }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const SendSmsService = async (phones, additional_phones, message) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${sendSmsPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({ phones, additional_phones, message }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const AddSmsPersonService = async (
  name,
  surname,
  phone,
  title,
  group,
  description
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addSmsPerson}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({ name, surname, phone, title, group, description }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const SmsUserService = async () => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${smsUserPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({}),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const SmsCreditService = async () => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${smsCreditsPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({}),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const SmsPersonsService = async () => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${smsPersonsPath}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({}),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const AddExcelSmsUserService = async (ex_data) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${AddExcelSmsUserPath}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          ex_data,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Customer/Panel/Serices
export const GetTracksAndLicenceByUniqueId = async (unique_id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getTracksAndLicenceByUniqueId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/Employees/OtherAccount

export const AddOtherAccounts = async (
  unique_id,
  user_name,
  platform,
  password,
  mail,
  opening_date,
  follower_count
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${addOtherAccounts}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
          user_name,
          platform,
          password,
          mail,
          opening_date,
          follower_count,
        }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const GetOtherAccounts = async (unique_id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${getOtherAccounts}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          unique_id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const UpdateOtherAccounts = async (editedData) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${updateOtherAccounts}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          editedData,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteOtherAccounts = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteOtherAccounts}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/Domain
export const GetDomain = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getDomain}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Panel/NoteEditor

export const AddNoteEditor = async (unique_id, text, finisher) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addNoteEditor}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        text,
        finisher,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const GetNoteEditor = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getNoteEditor}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//PanelLanguages
export const AddLanguages = async (
  unique_id,
  platform,
  languages,
  visitor,
  month
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addLanguages}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        platform,
        languages,
        visitor,
        month,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const GetLanguages = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getLanguages}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteLanguages = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteLanguages}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//PanelPages
export const AddPagesGraph = async (
  unique_id,
  platform,
  pages,
  visitor,
  screening,
  month
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addPagesGraph}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
        platform,
        pages,
        visitor,
        screening,
        month,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const GetPagesGraph = async (unique_id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${getPagesGraph}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        unique_id,
      }),
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeletePagesGraph = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deletePagesGraph}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Customer/Quatation
export const AddCustomerForm = async (
  form,
  urgency,
  cookie_permission,
  country_phone,
  selectedPackages
) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${addCustomerForm}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          form,
          urgency,
          cookie_permission,
          country_phone,
          selectedPackages,
        }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const AllCustomerForm = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${allCustomerForm}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const DeleteCustomerForm = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteCustomerForm}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const TransferCustomerForm = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${transferCustomerForm}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Brand
export const AddBrand = async (
  application_number,
  brand,
  customer,
  owner,
  starting_date,
  status,
  niceClasses,
  renewal_date
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addBrand}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        application_number,
        brand,
        customer,
        owner,
        starting_date,
        status,
        niceClasses,
        renewal_date,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const UpdateBrand = async (
  id,
  applicationNumber,
  brand,
  customer,
  owner,
  starting_date,
  status,
  niceClasses,
  renewal_date
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${updateBrand}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        applicationNumber,
        brand,
        customer,
        owner,
        starting_date,
        status,
        niceClasses,
        renewal_date,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteBrand = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteBrand}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const AddTimeBrand = async (id, time) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addTimeBrand}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        time,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Supplier

export const AddSupplier = async (
  responsible_person,
  company,
  address,
  phone,
  activity_area
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addSupplier}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        responsible_person,
        company,
        address,
        phone,
        activity_area,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const UpdateSupplier = async (
  id,
  responsible_person,
  company,
  address,
  phone,
  activity_area
) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${updateSupplier}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        responsible_person,
        company,
        address,
        phone,
        activity_area,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteSupplier = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteSupplier}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const AllSupplier = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${allSupplier}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

//Services
export const AddServices = async (form) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addServices}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        form,
      }),
    });
    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const AllServices = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${allServices}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const UpdateServices = async (id, form) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${updateServices}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
        form,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
export const DeleteServices = async (id) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${deleteServices}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        id,
      }),
    });

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

//Guarantee

export const AddGuarantee = async (form) => {
  try {
    const response = await fetch(`${baseEndpoint}${apiPath}${addGuarantee}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "include",
      body: JSON.stringify({
        form,
      }),
    });
    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const AllGuarantee = async () => {
  try {
    const response = await axios.get(
      `${baseEndpoint}${apiPath}${allGuarantee}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export const UpdateGuarantee = async (id, form) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${updateGuarantee}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          form,
        }),
      }
    );
    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const DeleteGuarantee = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${deleteGuarantee}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );
    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const VisibileGuarantee = async (id) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${visibileGuarantee}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
        }),
      }
    );
    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};

export const AddTimeGuarantee = async (id, time) => {
  try {
    const response = await fetch(
      `${baseEndpoint}${apiPath}${addTimeGuarantee}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
        body: JSON.stringify({
          id,
          time,
        }),
      }
    );

    const data = await response.json();
    window.location.reload();
    return data;
  } catch (error) {
    console.error("Giriş başarısız:", error.message);
    return false;
  }
};
